import { ReactNode } from 'react';
import { stylesheet } from 'typestyle';

interface IProps {
  children: ReactNode;
}

function CardElement(props: IProps): JSX.Element {
  return <div className={classNames.container} children={props.children} />;
}

const classNames = stylesheet({
  container: {
    margin: '0 auto',
    backgroundColor: '#ffffff',
    borderRadius: 10,
    boxShadow: '0 6px 20px 0 #00000022',
    width: '100%',
    padding: 20,
  },
});

export { CardElement };
