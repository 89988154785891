import { useCrudService } from '../common/crud-service.hook';
import { userService } from './user.service';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types -- Implicitly typed
function useUserService() {
  const useUserService = useCrudService(userService);
  return {
    useUserHttpOptions: useUserService.useHttpOptions,
    useGetAllUsers: useUserService.useGetAll,
    useGetUser: useUserService.useGet,
  };
}

export { useUserService };
