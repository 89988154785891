import { stylesheet } from 'typestyle';
import { colors } from '../../domain/common/constants/color.constants';
import { FlexBoxElement } from './flex-box.element';

interface IProps {
  error: string;
}

const ErrorMessageElement = (props: IProps): JSX.Element => (
  <FlexBoxElement>
    <p className={classNames.errorMessage}>{props.error}</p>
  </FlexBoxElement>
);

const classNames = stylesheet({
  errorMessage: {
    color: colors.dangerous,
  },
});

export { ErrorMessageElement };
