import { IUser } from '@hulanbv/teamup';
import { IHttpOptions } from 'nest-utilities-client';
import { Fragment, useCallback, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { attendanceService } from '../../domain/attendance/attendance.service';
import { dictionary } from '../../domain/common/constants/dictionary.constants';
import { useEffectAsync } from '../../domain/common/effect-async.hook';
import { dateToDayRangeDates } from '../../domain/common/utilities/date-to-day-range-dates';
import { useGroupNotesService } from '../../domain/group-notes/group-notes.hook';
import { useGroupService } from '../../domain/group/group-service.hook';
import { useNotificationContext } from '../../domain/notification/notification-context.hook';
import { ActionButtonElement } from '../elements/action-button.element';
import { BreadCrumbsElement } from '../elements/bread-crumbs.element';
import { CardElement } from '../elements/card.element';
import { ContainerElement } from '../elements/container.element';
import { FlexBoxElement } from '../elements/flex-box.element';
import { GroupNoteElement } from '../elements/group-note-element';
import { InputTextElement } from '../elements/input-text.element';
import { OverflowScrollElement } from '../elements/overflow-scroll.element';
import { SpacerElement } from '../elements/spacer.element';
import { SpinnerElement } from '../elements/spinner.element';
import { UserTableTemplate } from '../templates/user-table.template';
import { userService } from '../../domain/user/user.service';
import { usePagination } from '../../domain/common/use-pagination.hook';

const dateEightWeeksAgo = new Date(
  Date.now() - 8 * 7 * 24 * 60 * 60 * 1000,
).toISOString();
const dateOneHourAgo = new Date(Date.now() - 1 * 60 * 60 * 1000).toISOString();

function GroupScreen(): JSX.Element {
  const navigate = useNavigate();
  const { groupId } = useParams();
  const [userSearchQuery, setUserSearchQuery] = useState<string>('');
  const { appendError } = useNotificationContext();

  // Fetch the group
  const { useGetGroup, useGroupHttpOptions } = useGroupService();
  const getUseGroupHttpOptions = useGroupHttpOptions(
    function factory(): IHttpOptions {
      return {
        populate: ['members', 'location'],
        select: ['name', 'memberIds', 'locationId'],
      };
    },
    [],
  );
  const { document: group, isBusy: isGetGroupBusy } = useGetGroup(
    groupId,
    getUseGroupHttpOptions,
  );

  // Fetch the group notes
  const { useGetAllGroupNotes, useGroupNotesHttpOptions } =
    useGroupNotesService();
  const getUseGroupNotesHttpOptions = useGroupNotesHttpOptions(
    function factory(): IHttpOptions {
      return {
        match: {
          groupId,
        },
        populate: ['author', 'group'],
        limit: 1,
        sort: ['-createdAt'],
      };
    },
    [groupId],
  );
  const { documents: groupNotes, headers } = useGetAllGroupNotes(
    getUseGroupNotesHttpOptions,
  );

  // Fetch all the users
  const paginateGroupMembers = useCallback(
    async (skip: number, limit: number) => {
      const { data: users } = await userService.getAll({
        match: {
          // eslint-disable-next-line @typescript-eslint/naming-convention -- This is a special case.
          'groups._id': groupId,
          $or: [
            { firstName: { $regex: userSearchQuery, $options: 'i' } },
            { lastName: { $regex: userSearchQuery, $options: 'i' } },
          ],
        },
        addFields: {
          isInactive: {
            $cond: [
              {
                $lt: [
                  {
                    $ifNull: [
                      { $dateToString: { date: '$attendedAt' } },
                      new Date().toISOString(),
                    ],
                  },
                  dateEightWeeksAgo,
                ],
              },
              1,
              0,
            ],
          },
          isNew: {
            $cond: [
              {
                $gt: [
                  {
                    $ifNull: [
                      { $dateToString: { date: '$createdAt' } },
                      new Date().toISOString(),
                    ],
                  },
                  dateOneHourAgo,
                ],
              },
              1,
              0,
            ],
          },
          fullName: {
            $toLower: {
              $concat: ['$firstName', { $ifNull: ['$lastName', ''] }],
            },
          },
        },
        sort: ['isInactive', '-isNew', 'fullName', '_id'],
        offset: skip,
        limit,
      });
      return users;
    },
    [groupId, userSearchQuery],
  );

  const {
    items: users,
    nextPage,
    hasReachedLimit,
    isLoading: isLoadingUsers,
  } = usePagination(paginateGroupMembers, 25);

  // Fetch all the attendance dates for this group
  // TODO -- Implemented this into the service hook.
  const [isGetAllAttendanceDatesBusy, setIsGetAllAttendanceDatesBusy] =
    useState(true);
  const [attendanceDates, setAttendanceDates] = useState<Date[]>([]);
  useEffectAsync(async function effect() {
    try {
      const response = await attendanceService.getDatesInTimezone(groupId);
      setAttendanceDates(response.hydrated);
    } catch {
      appendError(dictionary.literals.unableToFetchData);
    }
    setIsGetAllAttendanceDatesBusy(false);
  }, []);

  function handleOnClickMarkAttendance() {
    navigate(`/mark-attendance?group-id=${groupId}`);
  }

  const handleOnClickEditUser = useCallback(
    (user: IUser) => {
      navigate(`/users/${user.id}/edit?group-id=${groupId}`);
    },
    [navigate, groupId],
  );

  function handleOnClickViewAttendance(date: Date) {
    const [rangeFromDate, rangeToDate] = dateToDayRangeDates(date);
    const url = [
      `/attendance?group-id=${groupId}`,
      `&range-from-timestamp=${rangeFromDate.getTime()}`,
      `&range-to-timestamp=${rangeToDate.getTime()}`,
    ].join('');
    navigate(url);
  }

  const handleOnClickViewAllNotes = useCallback(() => {
    navigate('cancelations');
  }, [navigate]);

  // Calculate the total number of notes
  const totalNotes = useMemo(
    () => Number(headers?.get('x-total-count')) - 1,
    [headers],
  );

  if (group === null) {
    return (
      <ContainerElement>
        <FlexBoxElement>
          <SpacerElement />
          <SpinnerElement />
          <p>{dictionary.literals.loading}...</p>
        </FlexBoxElement>
      </ContainerElement>
    );
  }

  return (
    <ContainerElement>
      <BreadCrumbsElement
        crumbs={[
          dictionary.literals.home,
          dictionary.literals.groups,
          group.name,
        ]}
      />
      <SpacerElement />
      <h1>
        {dictionary.literals.group} {group.name}
      </h1>
      <SpacerElement />
      {isLoadingUsers === true && isGetGroupBusy === true && (
        <Fragment>
          <p>{dictionary.literals.loading}...</p>
          <SpacerElement />
        </Fragment>
      )}
      <p>
        {dictionary.texts.isPartOfAt(group.name, group.location?.name ?? '')}
      </p>
      <SpacerElement />
      <CardElement>
        <p>{dictionary.literals.containingXUsers(group.memberIds.length)}</p>
        <h3>{dictionary.literals.children}</h3>
        <SpacerElement />
        <InputTextElement
          onChange={setUserSearchQuery}
          onChangeTimeoutMiliseconds={500}
          placeholder={`${dictionary.literals.search}...`}
        />
        <SpacerElement />
        <OverflowScrollElement>
          <UserTableTemplate
            rowActions={[
              {
                label: dictionary.literals.edit,
                callback: handleOnClickEditUser,
              },
            ]}
            users={users}
            searchQuery={userSearchQuery}
          />
        </OverflowScrollElement>
        {hasReachedLimit === false && group.memberIds.length > 25 && (
          <>
            <SpacerElement />
            <ActionButtonElement
              onClick={nextPage}
              flavour="secondary"
              children={dictionary.literals.showMore}
            />
          </>
        )}
        <SpacerElement />
        <ActionButtonElement
          onClick={handleOnClickMarkAttendance}
          children={dictionary.literals.markAttendance}
        />
      </CardElement>

      <SpacerElement />
      <CardElement>
        <h3>{dictionary.literals.canceledSessions}</h3>
        {totalNotes > 1 && (
          <p>{dictionary.texts.xotherCancelations(totalNotes)}</p>
        )}
        <SpacerElement />
        {groupNotes.length === 0 && (
          <p>{dictionary.literals.noSessionsHaveBeenCanceled}</p>
        )}
        {groupNotes.length > 0 && (
          <GroupNoteElement groupNote={groupNotes[0]} />
        )}
        <SpacerElement />
        <ActionButtonElement
          onClick={handleOnClickViewAllNotes}
          children={dictionary.literals.goToCancelations}
        />
      </CardElement>

      <SpacerElement />
      {isGetAllAttendanceDatesBusy === true && (
        <p>
          {dictionary.literals.loading} {dictionary.literals.attendance}...
        </p>
      )}
      <CardElement>
        <p>{dictionary.literals.previousAttendanceDates}</p>
        <h3>{dictionary.literals.attendance}</h3>
        {attendanceDates.map((attendanceDate, index) => (
          <Fragment key={index}>
            <SpacerElement />
            <ActionButtonElement
              onClick={() => handleOnClickViewAttendance(attendanceDate)}
              children={attendanceDate.toLocaleDateString()}
            />
          </Fragment>
        ))}
      </CardElement>
    </ContainerElement>
  );
}

export { GroupScreen };
