/**
 * Converts a date into two dates representing the start and end of the given
 * date's day.
 * @param date The date to convert to a day range
 * @returns Two dates representing the start and end of the given date's day
 */
function dateToDayRangeDates(date: Date): [from: Date, to: Date] {
  const from = new Date(date);
  const to = new Date(date);
  from.setHours(0, 0, 0, 0);
  to.setHours(23, 59, 59, 999);
  return [from, to];
}

export { dateToDayRangeDates };
