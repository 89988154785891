import { ReactNode } from 'react';
import { stylesheet } from 'typestyle';

interface IProps {
  children: ReactNode;
}

/**
 * Allows certain elements to overflow their container.
 * @param props The props
 * @returns An element
 */
function OverflowScrollElement(props: IProps): JSX.Element {
  return (
    <div className={classNames.container}>
      <div className={classNames.overflowIndicator}></div>
      <div className={classNames.overflowContainer}>{props.children}</div>
    </div>
  );
}

const classNames = stylesheet({
  container: {
    position: 'relative',
  },
  overflowIndicator: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    width: 40,
    background: 'linear-gradient(to right, #fff0, #fff)',
  },
  overflowContainer: {
    width: '100%',
    overflowX: 'scroll',
  },
});

export { OverflowScrollElement };
