import { useCrudService } from '../common/crud-service.hook';
import { groupNotesService } from './group-notes.service';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types -- Implicitly typed
function useGroupNotesService() {
  const useGroupNotesService = useCrudService(groupNotesService);
  return {
    useGroupNotesHttpOptions: useGroupNotesService.useHttpOptions,
    useGetAllGroupNotes: useGroupNotesService.useGetAll,
    useGetGroupNote: useGroupNotesService.useGet,
  };
}

export { useGroupNotesService };
