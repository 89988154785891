import { CSSProperties, useMemo } from 'react';

interface IProps {
  direction?: 'vertical' | 'horizontal';
  minimalLength?: number;
}

function SpacerElement(props: IProps): JSX.Element {
  const style = useMemo<CSSProperties>(
    function factory() {
      const minimalLenght = props.minimalLength || 20;
      const direction = props.direction || 'vertical';
      return direction === 'vertical'
        ? { minHeight: minimalLenght }
        : { minWidth: minimalLenght };
    },
    [props],
  );

  return <div style={style} />;
}

export { SpacerElement };
