import { Fragment, useCallback, useState } from 'react';
import { useParams, useNavigate } from 'react-router';
import { authenticationService } from '../../domain/authentication/authentication.service';
import { dictionary } from '../../domain/common/constants/dictionary.constants';
import { useForm } from '../../domain/form/form.hook';
import { useNotificationContext } from '../../domain/notification/notification-context.hook';
import { ActionButtonElement } from '../elements/action-button.element';
import { ErrorMessageElement } from '../elements/error-message.element';
import { InputTextElement } from '../elements/input-text.element';
import { SpacerElement } from '../elements/spacer.element';

function ResetPasswordFormTemplate(): JSX.Element {
  const { token } = useParams();
  const { onSubmitForm: onSubmit } = useForm();
  const { appendError, appendSuccess } = useNotificationContext();
  const navigate = useNavigate();

  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const handleResetPassword = useCallback(
    async (formData) => {
      setErrorMessage(null);

      // Get the password and repeat password values.
      const password = formData.get('password');
      const repeatPassword = formData.get('repeatPassword');

      // If the password and repeat password fields do not match, set the
      // error message and return.
      if (password !== repeatPassword) {
        setErrorMessage(dictionary.texts.letPasswordMatch);
        return;
      }

      // Add the token to the form data.
      formData.append('token', token);
      // Remove the repeat password from the form data. It is not needed
      // to send to the server.
      formData.delete('repeatPassword');

      try {
        await authenticationService.redeemPassword(formData);
        appendSuccess(dictionary.texts.resetPasswordSuccessful);
        navigate('/login', { replace: true });
      } catch (error) {
        appendError(dictionary.texts.expiredResetPasswordToken);
      }
    },
    [token, appendSuccess, appendError, navigate],
  );

  return (
    <Fragment>
      {errorMessage && <ErrorMessageElement error={errorMessage} />}
      <SpacerElement />
      <form onSubmit={onSubmit(handleResetPassword)}>
        <InputTextElement
          name="password"
          type="password"
          minLength={3}
          placeholder={dictionary.literals.password}
          required
        />
        <SpacerElement />
        <InputTextElement
          name="repeatPassword"
          type="password"
          minLength={3}
          placeholder={dictionary.literals.repeatPassword}
          required
        />
        <SpacerElement />
        <ActionButtonElement
          role="submitClosestForm"
          children={dictionary.literals.resetPassword}
        />
      </form>
    </Fragment>
  );
}

export { ResetPasswordFormTemplate };
