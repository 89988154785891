import { useCrudService } from '../common/crud-service.hook';
import { locationService } from './location.service';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types -- Implicitly typed
function useLocationService() {
  const useLocationService = useCrudService(locationService);
  return {
    useLocationHttpOptions: useLocationService.useHttpOptions,
    useGetAllLocations: useLocationService.useGetAll,
    useGetLocation: useLocationService.useGet,
  };
}

export { useLocationService };
