import { ChangeEvent, MutableRefObject, RefObject, useRef } from 'react';
import { stylesheet } from 'typestyle';

interface IProps {
  name?: string;
  required?: boolean;
  placeholder?: string;
  onChangeTimeoutMiliseconds?: number;
  value?: string;
  type?: 'text' | 'number' | 'password' | 'email' | 'date';
  defaultValue?: string | number;
  minLength?: number;
  elementRef?: RefObject<HTMLInputElement>;
  onChange?: (value: string) => void;
}

function InputTextElement(props: IProps): JSX.Element {
  const searchTimeout: MutableRefObject<number> = useRef(0);

  function handleOnChange(event: ChangeEvent<HTMLInputElement>) {
    // Clears the timeout if it has already been set. This will prevent the
    // previous task from executing.
    if (searchTimeout.current) {
      window.clearTimeout(searchTimeout.current);
      searchTimeout.current = 0;
    }

    searchTimeout.current = window.setTimeout(() => {
      props.onChange?.(event.target.value);
    }, props.onChangeTimeoutMiliseconds);
  }

  return (
    <input
      ref={props.elementRef}
      className={classNames.input}
      type={props.type || 'text'}
      name={props.name}
      value={props.value}
      minLength={props.minLength}
      required={props.required}
      placeholder={props.placeholder}
      onChange={handleOnChange}
      defaultValue={props.defaultValue}
    />
  );
}

const classNames = stylesheet({
  input: {
    width: '100%',
    fontFamily: '"DM Sans", sans-serif',
    padding: '15px 20px',
    backgroundColor: '#ccc',
    borderRadius: 10,
    boxShadow: '0 10px 20px 0 #00000022',
    border: 'none',
    color: 'black',
    fontSize: '1.1rem',
  },
});

export { InputTextElement };
