import { Gender, IGroup } from '@hulanbv/teamup';

const baseTranslationsEnglish = {
  literals: {
    noSessionsHaveBeenCanceled: 'No sessions have been canceled',
    goToCancelations: 'Go to cancelations',
    canceledSessions: 'Canceled sessions',
    title: 'TeamUp',
    volunteerEnvironment: 'Volunteer Environment',
    login: 'Login',
    resetPassword: 'Reset password',
    forgotPassword: 'Forgot password',
    enterYourEmailaddress: 'Enter your email address',
    enterYourNewPassword: 'Enter your new password',
    repeatPassword: 'Repeat password',
    logout: 'Logout',
    welcomeBack: 'Welcome back',
    user: 'User',
    readyForTheDay: 'Ready for the day',
    heyThere: 'Hey there',
    yourLocations: 'Your locations',
    yourGroups: 'Your groups',
    loading: 'Loading',
    yourLocationsAndGroups: 'Your locations and groups',
    attendanceEnvironment: 'Attendance Environment',
    viewLocationDetails: 'View location details',
    viewGroupDetails: 'View group details',
    markAttendance: 'Mark attendance',
    removedAttendance: 'Removed attendance',
    location: 'Location',
    locationIn: 'Location in',
    members: 'Members',
    previousAttendanceDates: 'Previous attendance dates',
    viewAttendance: 'View attendance',
    attendance: 'Attendance',
    containingXUsers: (x?: number) => `Containing ${x ?? 'no'} users`,
    noAttendancesMarkedYet: 'No attendances marked yet',
    confirm: 'Confirm',
    attendanceMarkedSuccessfully: 'Attendance marked successfully',
    noChildrenSelected: 'No children selected',
    from: 'From',
    to: 'To',
    bornIn: 'Born in',
    email: 'Email',
    password: 'Password',
    useYourCredentialsToLogin: 'Use your credentials to login',
    selectChildren: 'Select children',
    byeBye: 'Bye bye',
    unableToFindWhatYoureLookingFor: "Unable to find what you're looking for",
    children: 'Children',
    remove: 'Remove',
    teamup: 'TeamUp',
    groups: 'Groups',
    group: 'Group',
    home: 'Home',
    registerNewChild: 'Register new child',
    editChildDetails: 'Edit child details',
    editDetailsOf: (name: string) => `Edit details of ${name}`,
    confirmAttendance: 'Confirm attendance',
    firstName: 'First name',
    lastName: 'Last name',
    register: 'Register',
    applyChanges: 'Apply changes',
    childRegisteredSuccessfully: 'Child registered successfully',
    childEditedSuccessfully: 'Child edited successfully',
    name: 'Name',
    gender: 'Gender',
    yearOfBirth: 'Year of birth',
    countryOfOrigin: 'Country of origin',
    selectOneOption: 'Select one option',
    clearSelection: 'Clear selection',
    selectGender: 'Select Gender',
    selectCountryOfOrigin: 'Select country of origin',
    unknown: 'Unknown',
    search: 'Search',
    attendances: 'Attendances',
    estAge: 'Est. age',
    invalidDate: 'Invalid date',
    date: 'Date',
    changeAttendanceDate: 'Change attendance date',
    changeAttendanceDateFailed: 'Change attendance date failed',
    changeAttendanceDateSuccessful: 'Change attendance date successful',
    unableToFetchData: 'Unable to fetch data',
    edit: 'Edit',
    removeFromGroup: 'Remove from group',
    childDeletedSuccessfully: 'Child deleted successfully',
    deleteChild: 'Delete child',
    showMore: 'Show more',
    notSpecified: 'Not specified',
    passwordsDoNotMatch: 'Passwords do not match',
    backTologin: 'Back to login',
    groupNotes: 'Group notes',
    notes: 'Notes',
    goToNotes: 'Go to notes',
    giveAReason: 'Give a reason',
    noteCreatedSuccessfully: 'Note successfully created ',
    noteCreationFailed: 'Note creation failed',
    noNotesHaveBeenAdded: 'No notes have been added',
    enterYourMessage: 'Enter your message',
    sessionIsCanceled: 'Session is canceled',
    noReasonSelected: 'No reason selected',
    noChildren: 'No children',
    notEnoughFacilitators: 'Not enough facilitators',
    otherwiseNamely: 'Otherwise, namely',
  },
  texts: {
    loginFailed: 'Incorrect login, please check your credentials and try again',
    resetPasswordFailed:
      'Something went wrong while resetting your password, try again',
    resetPasswordRequest:
      'A link will be sent to the provided email if it matches an account',
    expiredResetPasswordToken:
      'The reset password token has expired, please request a new one',
    resetPasswordSuccessful:
      'Your password has successfully been reset, you can now login',
    childRegistrationFailed:
      'Something went wrong while registering, please try again',
    enterValidPassword:
      'Please enter a valid password, it should be at least 3 characters long',
    letPasswordMatch: 'Please make sure that the passwords match and try again',
    childEditingFailed: 'Something went wrong while editing, please try again',
    childDeletionFailed:
      'Something went wrong while deleting, please try again',
    removeAttendanceFailed:
      'Something went wrong while removing attendance, please try again',
    sureDeleteChild: 'Are you sure you want to delete this child?',
    sureRemoveAttendance: 'Are you sure you want to remove attendance?',
    sessionIsCanceledBecauseNoChildren:
      'The session is canceled because no children are present',
    sessionIsCanceledBecauseNoSessions:
      'There will be no sessions today because no sessions are planned',
    aboutGroupSize: (group: IGroup) =>
      `Has ${group.volunteerIds.length} volunteers and ${group.memberIds.length} children`,
    isPartOf: (thing: string) => `Is part of ${thing}`,
    isPartOfAt: (groupName: string, locationName: string) =>
      `Is part of ${groupName} at ${locationName}`,
    cancelationsOfGroupX: (groupName: string) => `Cancelations of ${groupName}`,
    xotherCancelations: (totalCancelations: number) => {
      if (totalCancelations === 1) {
        return `${totalCancelations} other cancelation`;
      }
      return `${totalCancelations} other cancelations`;
    },
  },
  abbreviations: {
    gender: function factory(gender: Gender | undefined): string {
      switch (gender) {
        case Gender.MALE:
          return 'Male';
        case Gender.FEMALE:
          return 'Female';
        default:
          return 'Unidentified';
      }
    },
  },
};

const translationsDutch: typeof baseTranslationsEnglish = {
  literals: {
    noSessionsHaveBeenCanceled: 'Er zijn geen sessies afgelast',
    goToCancelations: 'Ga naar afgelaste sessies',
    canceledSessions: 'Afgelaste sessies',
    title: 'TeamUp',
    volunteerEnvironment: 'Vrijwilligers Omgeving',
    login: 'Inloggen',
    resetPassword: 'Reset wachtwoord',
    forgotPassword: 'Wachtwoord vergeten',
    enterYourEmailaddress: 'Voer je emailadres in',
    enterYourNewPassword: 'Voer je nieuwe wachtwoord in',
    repeatPassword: 'Herhaal wachtwoord',
    logout: 'Uitloggen',
    welcomeBack: 'Welkom terug',
    user: 'Gebruiker',
    readyForTheDay: 'Klaar voor de dag',
    heyThere: 'Hallo daar',
    yourLocations: 'Jouw locaties',
    yourGroups: 'Jouw groepen',
    loading: 'Laden',
    yourLocationsAndGroups: 'Jouw locaties en groepen',
    attendanceEnvironment: 'Aanwezigheidsomgeving',
    viewLocationDetails: 'Bekijk locatie details',
    viewGroupDetails: 'Bekijk groep details',
    markAttendance: 'Markeer aanwezigheid',
    removedAttendance: 'Aanwezigheid verwijderd',
    location: 'Locatie',
    locationIn: 'Locatie in',
    members: 'Leden',
    previousAttendanceDates: 'Vorige aanwezigheidsdatums',
    viewAttendance: 'Bekijk aanwezigheid',
    attendance: 'Aanwezigheid',
    containingXUsers: (x?: number) => `Met ${x ?? 'geen'} gebruikers`,
    noAttendancesMarkedYet: 'Nog geen aanwezigheden gemarkeerd',
    confirm: 'Bevestigen',
    attendanceMarkedSuccessfully: 'Aanwezigheid succesvol gemarkeerd',
    noChildrenSelected: 'Geen kinderen geselecteerd',
    from: 'Van',
    to: 'Tot',
    bornIn: 'Geboren in',
    email: 'Email',
    password: 'Wachtwoord',
    useYourCredentialsToLogin: 'Gebruik je inloggegevens om in te loggen',
    selectChildren: 'Selecteer kinderen',
    byeBye: 'Bye Bye',
    unableToFindWhatYoureLookingFor: 'Kan je niet vinden wat je zoekt',
    children: 'Kinderen',
    remove: 'Verwijderen',
    teamup: 'TeamUp',
    groups: 'Groepen',
    group: 'Groep',
    home: 'Thuis',
    registerNewChild: 'Nieuw kind registreren',
    editChildDetails: 'Kind gegevens bewerken',
    editDetailsOf: (name: string) => `Edit details of ${name}`,
    confirmAttendance: 'Aanwezigheid bevestigen',
    firstName: 'Voornaam',
    lastName: 'Achternaam',
    register: 'Registreren',
    applyChanges: 'pas toe',
    childRegisteredSuccessfully: 'Kind succesvol geregistreerd',
    childEditedSuccessfully: 'Kind succesvol bewerkt',
    name: 'Naam',
    gender: 'Geslacht',
    yearOfBirth: 'Geboortejaar',
    countryOfOrigin: 'Land van herkomst',
    selectOneOption: 'Kies een optie',
    clearSelection: 'Selectie wissen',
    selectGender: 'Selecteer geslacht',
    selectCountryOfOrigin: 'Selecteer land van herkomst',
    unknown: 'Onbekend',
    search: 'Zoeken',
    attendances: 'Aanwezigheden',
    estAge: 'Gesch. leeftijd',
    invalidDate: 'Ongeldige datum',
    date: 'Datum',
    changeAttendanceDate: 'Aanwezigheid datum wijzigen',
    changeAttendanceDateFailed: 'Aanwezigheid datum wijzigen mislukt',
    changeAttendanceDateSuccessful: 'Aanwezigheid datum wijzigen succesvol',
    unableToFetchData: 'Kan gegevens niet ophalen',
    edit: 'Bewerken',
    removeFromGroup: 'Uit groep verwijderen',
    childDeletedSuccessfully: 'Kind succesvol verwijderd',
    deleteChild: 'Kind verwijderen',
    showMore: 'Toon meer',
    notSpecified: 'Niet gespecificeerd',
    passwordsDoNotMatch: 'Wachtwoorden komen niet overeen',
    backTologin: 'Terug naar inloggen',
    groupNotes: 'Groepsnotities',
    notes: 'Notities',
    goToNotes: 'Ga naar notities',
    giveAReason: 'Geef reden op',
    noteCreatedSuccessfully: 'Notitie succesvol gemaakt',
    noteCreationFailed: 'Notitie maken mislukt',
    noNotesHaveBeenAdded: 'Er zijn nog geen notities toegevoegd',
    enterYourMessage: 'Typ je bericht',
    sessionIsCanceled: 'Sessie gaat niet door',
    noReasonSelected: 'Geen reden geselecteerd',
    noChildren: 'Geen kinderen',
    notEnoughFacilitators: 'Niet genoeg begeleiders',
    otherwiseNamely: 'Anders, namelijk',
  },
  texts: {
    loginFailed:
      'Onjuiste login, controleer uw inloggegevens en probeer het opnieuw',
    resetPasswordFailed:
      'Er ging iets mis tijdens het resetten van uw wachtwoord, probeer het opnieuw',
    resetPasswordRequest:
      'Er wordt een link naar het opgegeven e-mailadres verzonden als deze overeenkomt met een account',
    expiredResetPasswordToken:
      'Het token voor het resetten van het wachtwoord is verlopen. Vraag een nieuw token aan',
    resetPasswordSuccessful:
      'Het wachtwoord is succesvol opnieuw ingesteld. U kunt nu inloggen met uw nieuwe wachtwoord',
    enterValidPassword:
      'Voer een geldig wachtwoord in, het moet minimaal 3 tekens lang zijn',
    letPasswordMatch:
      'Controleer of de wachtwoorden overeenkomen en probeer het opnieuw',
    childRegistrationFailed:
      'Er ging iets mis tijdens het registreren, probeer het opnieuw',
    childEditingFailed:
      'Er ging iets mis tijdens het bewerken, probeer het opnieuw',
    childDeletionFailed:
      'Er ging iets mis tijdens het verwijderen, probeer het opnieuw',
    sureRemoveAttendance:
      'Weet je zeker dat je de aanwezigheid wilt verwijderen?',
    removeAttendanceFailed:
      'Er ging iets mis tijdens het verwijderen van de aanwezigheid, probeer het opnieuw',
    sureDeleteChild: 'Weet je zeker dat je dit kind wilt verwijderen?',
    sessionIsCanceledBecauseNoChildren:
      'Deze sessie ging niet door omdat er niet genoeg kinderen aanwezig waren',
    sessionIsCanceledBecauseNoSessions:
      'Deze sessie ging niet door omdat er geen sessies waren',
    aboutGroupSize: (group: IGroup) =>
      `Heeft ${group.volunteerIds.length} vrijwilligers en ${group.memberIds.length} kinderen`,
    isPartOf: (thing: string) => `Maakt deel uit van ${thing}`,
    isPartOfAt: (groupName: string, locationName: string) =>
      `Maakt deel uit van ${groupName} in ${locationName}`,
    cancelationsOfGroupX: (groupName: string) =>
      `Afgelaste sessies van ${groupName}`,
    xotherCancelations: (totalCancelations: number) => {
      if (totalCancelations === 1) {
        return `${totalCancelations} andere sessie afgelast`;
      }
      return `${totalCancelations} andere sessies afgelast`;
    },
  },
  abbreviations: {
    gender: function factory(gender: Gender | undefined): string {
      switch (gender) {
        case Gender.MALE:
          return 'Man';
        case Gender.FEMALE:
          return 'Vrouw';
        default:
          return 'Ongeidentificeerd';
      }
    },
  },
};

interface ITranslations {
  [countryCode: string]: typeof baseTranslationsEnglish;
}

const translations: ITranslations = {
  'en-GB': baseTranslationsEnglish,
  'en-US': baseTranslationsEnglish,
  'nl-NL': translationsDutch,
};

const { language } = navigator;

const dictionary = translations[language] ?? baseTranslationsEnglish;

export { dictionary };
