import { IUser } from '@hulanbv/teamup';
import { CrudService } from 'nest-utilities-client';
import { environment } from '../common/constants/environment.constants';
import { httpService } from '../common/http.service';

class UserService extends CrudService<IUser> {
  constructor() {
    super([environment.REACT_APP_API_URL, 'users'].join('/'), httpService);
  }
}

const userService = new UserService();

export { userService };
