import { Fragment } from 'react';
import { stylesheet } from 'typestyle';
import { ActionButtonElement } from '../../components/elements/action-button.element';
import { useFloatingActionButtonContext } from './floating-action-button-context.hook';

function FloatingActionButtonRenderer(): JSX.Element {
  const { isActive, options } = useFloatingActionButtonContext();

  if (isActive === false && options?.isHidden !== true) {
    return <Fragment />;
  }

  function handleOnClick() {
    options?.callback?.();
  }

  return (
    <div className={classNames.element}>
      <ActionButtonElement
        onClick={handleOnClick}
        hasDarkShadow
        isFullWidth={false}
      >
        {options?.content ?? ''}
      </ActionButtonElement>
    </div>
  );
}

const classNames = stylesheet({
  element: {
    display: 'inline-flex',
    justifyContent: 'flex-end',
    maxWidth: 800,
    width: '100%',
  },
});

export { FloatingActionButtonRenderer };
