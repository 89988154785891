import { useCallback, useEffect, useState } from 'react';

type UsePaginationReturnType<ItemType> = {
  /**
   * A list of all items fetched using the use pagination hook. Items loaded
   * through nextPage() will be concatenated to this list.
   */
  items: ItemType[];

  /**
   * A boolean indicating if the hook is currently loading more items.
   */
  isLoading: boolean;

  /**
   * The page the paginator is currently on starting at 0.
   */
  currentPage: number;

  /**
   * A boolean indicating if the pagination has loaded all available items.
   * This boolean is set to true if the last called nextPage() function yielded
   * less items than the limit count.
   *
   * When this boolean has hit true the hook will no longer fetch new pages.
   */
  hasReachedLimit: boolean;

  /**
   * A function that can be called to load the items on the next page.
   * The Promise returned by this method will only contain the items from the
   * newly fetched page.
   */
  nextPage: () => Promise<ItemType[]>;

  /**
   * Resets the state of the hook manually. Returns the contents of the fetched
   * first page.
   */
  reset: () => Promise<ItemType[]>;
};

function usePagination<ItemType>(
  /**
   * The method which is called to fetch a page with items.
   */
  fetchMethod: (skip: number, limit: number) => Promise<ItemType[]>,

  /**
   * The number of items to be shown per page.
   */
  limit: number,
): UsePaginationReturnType<ItemType> {
  const [items, setItems] = useState<ItemType[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [hasReachedLimit, setHasReachedLimit] = useState(false);

  /**
   * Loads the content of the provided page number. The page results are returned
   * as well as concatenated to the items state to create a full list.
   */
  const loadPage = useCallback(
    async (page: number): Promise<ItemType[]> => {
      setIsLoading(true);
      const pageItems = await fetchMethod(page * limit, limit);
      setIsLoading(false);

      // add the page items to the complete list in the state
      setItems((oldItems) => (page > 0 ? oldItems : []).concat(pageItems));

      // if the fetched items are less than the defined limit, lock the hook
      setHasReachedLimit(pageItems.length < limit);

      return pageItems;
    },
    [fetchMethod, limit],
  );

  /**
   * Moves the pagination state to the next page if the hook isn't already loading
   * and hasn't reached the item limit.
   */
  const nextPage = useCallback(async (): Promise<ItemType[]> => {
    if (isLoading === true || hasReachedLimit === true) {
      return [];
    }

    const nextPage = currentPage + 1;
    setCurrentPage(nextPage);

    return loadPage(nextPage);
  }, [currentPage, hasReachedLimit, isLoading, loadPage]);

  /**
   * Resets the hook state after reloading the first page for smooth transitions
   */
  const reset = useCallback((): Promise<ItemType[]> => {
    const loadInitialPage = async () => {
      const pageItems = await loadPage(0);
      setCurrentPage(0);

      return pageItems;
    };

    return loadInitialPage();
  }, [loadPage]);

  /**
   * Loads the initial page and resets the hook if one of the parameters changes
   */
  useEffect(() => {
    reset();
  }, [reset]);

  return { items, isLoading, currentPage, hasReachedLimit, nextPage, reset };
}

export { usePagination };
