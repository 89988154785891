import { FormEvent, useCallback, useState } from 'react';

type OptionalVoidFunction = (() => void) | void;

interface IFormHook {
  onSubmitForm: (
    process: (
      formData: FormData,
      htmlFormElement: HTMLFormElement,
    ) => Promise<OptionalVoidFunction>,
  ) => (event: FormEvent<HTMLFormElement>) => Promise<void>;
  isBusy: boolean;
}

function useForm(): IFormHook {
  const [isBusy, setIsBusy] = useState(false);

  function setFormFieldsDisabled(
    htmlFormElement: HTMLFormElement,
    isDisabled: boolean,
  ) {
    const formFields = htmlFormElement.querySelectorAll('input');
    for (let i = 0; i < formFields.length; i += 1) {
      formFields[i].disabled = isDisabled;
    }
  }

  const onSubmitForm = useCallback(
    function callback(
      process: (
        formData: FormData,
        htmlFormElement: HTMLFormElement,
      ) => Promise<OptionalVoidFunction>,
    ) {
      // Returns a function that will be fed back to the form's onSubmit event.
      return async function handleOnSubmit(event: FormEvent<HTMLFormElement>) {
        event.preventDefault();
        if (isBusy === true) {
          return;
        }
        const htmlFormElement = event.currentTarget;
        if (typeof htmlFormElement !== 'undefined') {
          const formData = new FormData(htmlFormElement);
          setFormFieldsDisabled(htmlFormElement, true);
          setIsBusy(true);
          try {
            // The process is invoked with the form data.
            const callbackMethod = await process(formData, htmlFormElement);
            // Resetting the form and busy state.
            setFormFieldsDisabled(htmlFormElement, false);
            setIsBusy(false);
            if (typeof callbackMethod === 'function') {
              // The process returned a callback method which will be invoked
              // after the form is reset.
              callbackMethod();
            }
          } catch (error) {
            setFormFieldsDisabled(htmlFormElement, false);
            setIsBusy(false);
            throw error;
          }
        }
      };
    },
    [isBusy],
  );

  return { onSubmitForm, isBusy };
}

export { useForm };
