import { IAttendance } from '@hulanbv/teamup';
import { CrudService, IResponse } from 'nest-utilities-client';
import { environment } from '../common/constants/environment.constants';
import { httpService } from '../common/http.service';

class AttendanceService extends CrudService<IAttendance> {
  constructor() {
    super(
      [environment.REACT_APP_API_URL, 'attendances'].join('/'),
      httpService,
    );
  }

  async postSelectedUsers(
    locationId: string,
    attendedAt: Date,
    ...userIds: string[]
  ) {
    const requests: Promise<IResponse<IAttendance, null>>[] = [];
    for (const userId of userIds) {
      requests.push(attendanceService.post({ locationId, userId, attendedAt }));
    }
    await Promise.all(requests);
  }

  async getDatesInTimezone(
    groupId?: string,
  ): Promise<IResponse<string[], Date[]>> {
    // When getting the attendances dates, we'll append the timezone offset to
    // the url. This is because the date returned by the API is grouped by days
    // and does not include the user's timezone.
    const timezoneOffset = new Date().getTimezoneOffset() * -1;
    const response = await this.http.get(
      [this.controller, 'groups', groupId, 'dates', timezoneOffset].join('/'),
    );
    response.hydrated = response.data.map(function map(data: string) {
      // Parsing the string directly into a date object does not work. We'll
      // split it into strings and then parse it into a date object.
      const [year, month, day] = data.split('-');
      const hydrated = new Date(Number(year), Number(month) - 1, Number(day));
      return hydrated;
    });
    return response;
  }
}

const attendanceService = new AttendanceService();

export { attendanceService };
