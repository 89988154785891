const currentYear = new Date().getFullYear();

function yearOfBirthToAge(yearOfBirth?: number): number | null {
  if (typeof yearOfBirth === 'undefined' || yearOfBirth === 0) {
    return null;
  }
  const age = currentYear - yearOfBirth;
  if (age < 0 || age > 100) {
    return null;
  }
  return age;
}

export { yearOfBirthToAge };
