import { HttpService, IResponse } from 'nest-utilities-client';
import { useAuthentication } from '../authentication/authentication.hook';
import { authenticationService } from '../authentication/authentication.service';

class Service extends HttpService {
  public currentAuthenticationHook: ReturnType<
    typeof useAuthentication
  > | null = null;

  async getHeaders() {
    return {
      // eslint-disable-next-line @typescript-eslint/naming-convention -- naming convention is not respected for header
      Authorization: authenticationService.getSession()?.token ?? '',
    };
  }

  async onRequestError(error: IResponse<Error>) {
    if (error.status === 401) {
      this.currentAuthenticationHook?.logout();
    }
  }
}

const httpService = new Service();

export { httpService };
