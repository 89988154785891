import { Gender } from '@hulanbv/teamup';
import { useCallback, useRef } from 'react';
import { countryLabels } from '../../domain/common/constants/country-labels';
import { dictionary } from '../../domain/common/constants/dictionary.constants';
import { mapEnumKeys } from '../../domain/common/utilities/map-enum-keys';
import { ActionButtonElement } from '../elements/action-button.element';
import { InputSelectElement } from '../elements/input-select.element';
import { InputTextElement } from '../elements/input-text.element';
import { SpacerElement } from '../elements/spacer.element';

interface IProps {
  onSubmit: (formdata: FormData) => void | Promise<void>;
}

const RegisterChildFormTemplate = (props: IProps): JSX.Element => {
  const formRef = useRef<HTMLFormElement>(null);

  const handleClickSubmit = useCallback(() => {
    if (!formRef.current?.reportValidity() || !formRef.current) {
      return;
    }

    const formData = new FormData(formRef.current);
    props.onSubmit(formData);
  }, [props]);

  return (
    <>
      <SpacerElement minimalLength={30} />
      <h2>{dictionary.literals.registerNewChild}</h2>
      <SpacerElement />
      <form ref={formRef}>
        <InputTextElement
          name="firstName"
          placeholder={`${dictionary.literals.firstName}*`}
          required
        />
        <SpacerElement />
        <InputTextElement
          name="lastName"
          placeholder={dictionary.literals.lastName}
        />
        <SpacerElement />
        <InputTextElement
          name="age"
          type="number"
          placeholder={dictionary.literals.estAge}
        />
        <SpacerElement />
        <InputSelectElement
          name="gender"
          placeholder={`${dictionary.literals.selectGender}*...`}
          options={mapEnumKeys(Gender, (key) => ({
            label: dictionary.abbreviations.gender(Gender[key]),
            value: Gender[key].toString(),
          }))}
        />
        <SpacerElement />
        <InputSelectElement
          name="countryOfOrigin"
          emptyOptionLabel={dictionary.literals.notSpecified}
          placeholder={`${dictionary.literals.selectCountryOfOrigin}...`}
          isSearchable={true}
          options={Object.keys(countryLabels).map((key) => ({
            label: countryLabels[key],
            value: key,
          }))}
        />
        <SpacerElement />
        <ActionButtonElement
          onClick={handleClickSubmit}
          role="submitClosestForm"
          children={dictionary.literals.register}
        />
        <SpacerElement />
      </form>
    </>
  );
};

export { RegisterChildFormTemplate };
