import { ChangeEvent, useCallback } from 'react';
import { stylesheet } from 'typestyle';

interface IProps {
  name?: string;
  required?: boolean;
  placeholder?: string;
  value?: string;
  defaultValue?: string | number;
  autoFocus?: boolean;
  onChange?: (value: string) => void;
}

function InputTextAreaElement(props: IProps): JSX.Element {
  const handleOnChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => {
      props.onChange?.(event.target.value);
    },
    [props],
  );

  return (
    <textarea
      className={classNames.input}
      name={props.name}
      value={props.value}
      required={props.required}
      autoFocus={props.autoFocus}
      placeholder={props.placeholder}
      onChange={handleOnChange}
      defaultValue={props.defaultValue}
    />
  );
}

const classNames = stylesheet({
  input: {
    width: '100%',
    height: '200px',
    fontFamily: '"DM Sans", sans-serif',
    padding: '15px 20px',
    backgroundColor: '#ccc',
    borderRadius: 10,
    boxShadow: '0 10px 20px 0 #00000022',
    border: 'none',
    color: 'black',
    fontSize: '1.1rem',
    resize: 'none',
  },
});

export { InputTextAreaElement };
