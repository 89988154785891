import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

function useSearchParam(name: string): string | null {
  const [searchParams] = useSearchParams();

  return useMemo(
    function factory() {
      return searchParams.get(name);
    },
    [searchParams, name],
  );
}

export { useSearchParam };
