import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { Main } from './components/main';
import './styles/global.styles';

ReactDOM.render(
  <StrictMode>
    <Main />
  </StrictMode>,
  document.getElementById('root'),
);
