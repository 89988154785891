import { dictionary } from '../../domain/common/constants/dictionary.constants';
import { CardElement } from '../elements/card.element';
import { ContainerElement } from '../elements/container.element';
import { SpacerElement } from '../elements/spacer.element';
import { ResetPasswordFormTemplate } from '../templates/reset-password-form.template';

const ResetPasswordScreen = (): JSX.Element => (
  <ContainerElement>
    <h1>{dictionary.literals.resetPassword}</h1>
    <SpacerElement />
    <p>{dictionary.literals.enterYourNewPassword}</p>
    <SpacerElement />
    <CardElement>
      <ResetPasswordFormTemplate />
    </CardElement>
  </ContainerElement>
);

export { ResetPasswordScreen };
