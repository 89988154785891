import { IUser } from '@hulanbv/teamup';
import { Fragment, useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { useAttendanceService } from '../../domain/attendance/attendance-service.hook';
import { attendanceService } from '../../domain/attendance/attendance.service';
import { dictionary } from '../../domain/common/constants/dictionary.constants';
import { useSearchParam } from '../../domain/common/search-param.hook';
import { formatDate } from '../../domain/common/utilities/format-date';
import { useForm } from '../../domain/form/form.hook';
import { useGroupService } from '../../domain/group/group-service.hook';
import { useNotificationContext } from '../../domain/notification/notification-context.hook';
import { ActionButtonElement } from '../elements/action-button.element';
import { BreadCrumbsElement } from '../elements/bread-crumbs.element';
import { CardElement } from '../elements/card.element';
import { ContainerElement } from '../elements/container.element';
import { InputTextElement } from '../elements/input-text.element';
import { OverflowScrollElement } from '../elements/overflow-scroll.element';
import { SpacerElement } from '../elements/spacer.element';
import { UserTableTemplate } from '../templates/user-table.template';

function AttendanceScreen(): JSX.Element {
  const groupId = useSearchParam('group-id');
  const navigate = useNavigate();
  const { appendSuccess, appendError } = useNotificationContext();
  const { onSubmitForm: onSubmitChangeAttendanceDateForm } = useForm();
  const rangeFromTimestamp = useSearchParam('range-from-timestamp');
  const rangeToTimestamp = useSearchParam('range-to-timestamp');
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const rangeFromDate = useMemo(
    function factory() {
      return new Date(Number(rangeFromTimestamp || '0'));
    },
    [rangeFromTimestamp],
  );

  const rangeToDate = useMemo(
    function factory() {
      return new Date(Number(rangeToTimestamp || new Date()));
    },
    [rangeToTimestamp],
  );

  // Fetch the group
  const { useGetGroup } = useGroupService();
  const { document: group } = useGetGroup(groupId);

  // Fetch the attendances
  const { useGetAllAttendances, useAttendanceHttpOptions } =
    useAttendanceService();
  const getAllAttendancesHttpOptions = useAttendanceHttpOptions(
    function factory() {
      return {
        populate: ['user'],
        sort: ['attendedAt', 'user.firstName', 'user.lastName'],
        match: {
          // eslint-disable-next-line @typescript-eslint/naming-convention -- Match does not respect naming convention
          'user.groups._id': groupId,
          attendedAt: {
            $gte: rangeFromDate.toISOString(),
            $lt: rangeToDate.toISOString(),
          },
        },
      };
    },
    [],
  );
  const {
    documents: attendances,
    isBusy: isGetAttendancesBusy,
    refresh: refreshAttendances,
  } = useGetAllAttendances(getAllAttendancesHttpOptions);

  const handleOnClickRemoveAttendance = useCallback(
    async (user: IUser) => {
      const attendance = attendances.find(({ userId }) => userId === user.id);
      if (attendance === undefined) {
        return;
      }
      try {
        // HACK -- Implement an actual confirmation dialog.
        // eslint-disable-next-line no-alert -- This is a temporary solution.
        const didConfirm = window.confirm(
          dictionary.texts.sureRemoveAttendance,
        );
        if (didConfirm === true) {
          // The user will be removed from attendance
          await attendanceService.delete(attendance._id);
          appendSuccess(dictionary.literals.removedAttendance);
          refreshAttendances();
        }
      } catch {
        appendError(dictionary.texts.removeAttendanceFailed);
      }
    },
    [appendError, appendSuccess, attendances, refreshAttendances],
  );

  const handleOnSubmitChangeAttendance = useCallback(
    async (formData: FormData) => {
      try {
        // Get the changed attendance date from the form
        const changedAttendanceDate = new Date(`${formData.get('attendedAt')}`);
        if (
          changedAttendanceDate === null ||
          Number.isNaN(changedAttendanceDate.getTime()) === true ||
          changedAttendanceDate.getFullYear() < 1900
        ) {
          throw new Error(dictionary.literals.invalidDate);
        }
        // disables the button on submit to prevent multiple submissions
        setIsButtonDisabled(true);
        const promises = attendances.map((attendance) =>
          // each user in attendance will be updated with the new attendance date
          attendanceService.patch({
            id: attendance._id,
            attendedAt: changedAttendanceDate,
          }),
        );
        // wait for all promises to resolve
        await Promise.all(promises);
        appendSuccess(dictionary.literals.changeAttendanceDateSuccessful);
        // Navigate back to the group
        navigate(`/groups/${groupId}`, { replace: true });
        setIsButtonDisabled(false);
      } catch {
        appendError(dictionary.literals.changeAttendanceDateFailed);
      }
    },
    [appendError, appendSuccess, attendances, groupId, navigate],
  );

  return (
    <ContainerElement>
      <BreadCrumbsElement
        crumbs={[
          dictionary.literals.home,
          dictionary.literals.groups,
          group?.name ?? dictionary.literals.loading,
          dictionary.literals.attendance,
        ]}
      />
      <SpacerElement />
      <h1>Attendance for group</h1>
      <SpacerElement />
      {isGetAttendancesBusy === true && (
        <Fragment>
          <p>
            {dictionary.literals.loading} {dictionary.literals.attendance}...
          </p>
          <SpacerElement />
        </Fragment>
      )}
      <p>
        {dictionary.literals.from} {formatDate(rangeFromDate)}{' '}
        {dictionary.literals.to.toLocaleLowerCase()} {formatDate(rangeToDate)}
      </p>
      <SpacerElement />
      <CardElement>
        <p>{dictionary.literals.containingXUsers(attendances.length)}</p>
        <h3>{dictionary.literals.children}</h3>
        <SpacerElement />
        <form
          onSubmit={onSubmitChangeAttendanceDateForm(
            handleOnSubmitChangeAttendance,
          )}
        >
          <OverflowScrollElement>
            <UserTableTemplate
              rowActions={[
                {
                  label: dictionary.literals.remove,
                  callback: handleOnClickRemoveAttendance,
                },
              ]}
              users={attendances
                .reduce<IUser[]>(
                  (prev, { user }) => prev.concat(user ? [user] : []),
                  [],
                )
                .sort((a, b) => {
                  const fullNameA = [a.firstName, a.lastName ?? '']
                    .join('')
                    .toLowerCase();
                  const fullNameB = [b.firstName, b.lastName ?? '']
                    .join('')
                    .toLowerCase();

                  if (fullNameA === fullNameB) {
                    return 0;
                  }
                  return fullNameA > fullNameB ? 1 : -1;
                })}
            />
          </OverflowScrollElement>
          <SpacerElement />
          <InputTextElement
            name="attendedAt"
            placeholder={dictionary.literals.date}
            type="date"
            defaultValue={new Date().toISOString().split('T')[0]}
          />
          <SpacerElement />
          <ActionButtonElement
            isDisabled={isButtonDisabled}
            role="submitClosestForm"
            children={dictionary.literals.changeAttendanceDate}
          />
        </form>
      </CardElement>
    </ContainerElement>
  );
}

export { AttendanceScreen };
