import { ReactNode } from 'react';
import { stylesheet } from 'typestyle';

interface IProps {
  children: ReactNode;
}

const FooterElement = (props: IProps): JSX.Element => (
  <div className={classNames.footer}>{props.children}</div>
);

const classNames = stylesheet({
  footer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    gap: 10,
    pointerEvents: 'none',
    position: 'fixed',
    maxWidth: '800px',
    padding: 20,
    bottom: 0,
    right: 0,
    left: 0,
    margin: '0 auto',
    // Able to click on element when FAB or Notification is floating on top of it.
    $nest: {
      '& > div > *': {
        pointerEvents: 'auto',
      },
    },
  },
});

export { FooterElement };
