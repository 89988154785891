import { IGroupNote } from '@hulanbv/teamup';
import { stylesheet } from 'typestyle';
import { formatDate } from '../../domain/common/utilities/format-date';
import { CardElement } from './card.element';
import { FlexBoxElement } from './flex-box.element';

interface IProps {
  groupNote: IGroupNote;
}

const GroupNoteElement = (props: IProps): JSX.Element => (
  <CardElement>
    <FlexBoxElement direction="column" alignItems="flex-start" gap={5}>
      <p className={classNames.noteText}>{props.groupNote.body}</p>
      {props.groupNote.sessionDate && (
        <h6 className={classNames.noteSubText}>
          {props.groupNote.author?.firstName} {props.groupNote.author?.lastName}{' '}
          - {formatDate(new Date(props.groupNote.sessionDate))}
        </h6>
      )}
    </FlexBoxElement>
  </CardElement>
);

const classNames = stylesheet({
  noteText: {
    whiteSpace: 'pre-line',
    color: '#46486D',
  },
  noteSubText: {
    whiteSpace: 'pre-line',
  },
});

export { GroupNoteElement };
