import { Fragment } from 'react';
import { stylesheet } from 'typestyle';
import { colors } from '../../domain/common/constants/color.constants';
import { environment } from '../../domain/common/constants/environment.constants';
import { FlexBoxElement } from './flex-box.element';

function EnvironmentFlagElement(): JSX.Element {
  const reactAppEnvironment = environment.REACT_APP_ENVIRONMENT;
  let environmentLabel: string | null = null;

  if (reactAppEnvironment === 'DEV') {
    environmentLabel = 'Development Environment';
  } else if (reactAppEnvironment === 'ACC') {
    environmentLabel = 'Acceptance Environment';
  }

  if (environmentLabel === null) {
    return <Fragment />;
  }

  return (
    <div className={classNames.container}>
      <FlexBoxElement>
        <p className={classNames.text}>{environmentLabel}</p>
      </FlexBoxElement>
    </div>
  );
}

const classNames = stylesheet({
  container: {
    textAlign: 'center',
    pointerEvents: 'none',
    width: '100%',
    backgroundColor: colors.primary,
    boxShadow: `0 10px 20px 0 ${colors.primary}55`,
  },
  text: {
    color: 'white',
    padding: '10px 20px 14px 20px',
  },
});

export { EnvironmentFlagElement };
