function mapEnumKeys<EnumType, ReturnType>(
  enumInstance: EnumType,
  callback: (key: Extract<keyof EnumType, string>) => ReturnType,
): ReturnType[] {
  const result = [];
  // eslint-disable-next-line no-restricted-syntax -- Must loop enum values
  for (const enumEntry in enumInstance) {
    if (Number.isNaN(Number(enumEntry)) === true) {
      result.push(callback(enumEntry));
    }
  }
  return result;
}

export { mapEnumKeys };
