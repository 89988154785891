import { ReactNode } from 'react';
import { stylesheet } from 'typestyle';

interface IProps {
  children: ReactNode;
}

function ContainerElement(props: IProps): JSX.Element {
  return <div className={classNames.container} children={props.children} />;
}

const classNames = stylesheet({
  container: {
    margin: '0 auto',
    width: '100%',
    maxWidth: '800px',
    padding: 20,
  },
});

export { ContainerElement };
