import { IHttpOptions } from 'nest-utilities-client';
import { Role } from '@hulanbv/teamup';
import { Fragment, useCallback, useState } from 'react';
import { useNavigate } from 'react-router';
import { attendanceService } from '../../domain/attendance/attendance.service';
import { dictionary } from '../../domain/common/constants/dictionary.constants';
import { useSearchParam } from '../../domain/common/search-param.hook';
import { usePagination } from '../../domain/common/use-pagination.hook';
import { ageToYearOfBirth } from '../../domain/common/utilities/age-to-year-of-birth';
import { useFloatingActionButtonContext } from '../../domain/floating-action-button/floating-action-button-context.hook';
import { useForm } from '../../domain/form/form.hook';
import { useGroupService } from '../../domain/group/group-service.hook';
import { groupService } from '../../domain/group/group.service';
import { useNotificationContext } from '../../domain/notification/notification-context.hook';
import { useSheetContext } from '../../domain/sheet/sheet-context.hook';
import { userService } from '../../domain/user/user.service';
import { ActionButtonElement } from '../elements/action-button.element';
import { BreadCrumbsElement } from '../elements/bread-crumbs.element';
import { CardElement } from '../elements/card.element';
import { ContainerElement } from '../elements/container.element';
import { RegisterChildFormTemplate } from '../templates/register-child-form.template';
import { FlexBoxElement } from '../elements/flex-box.element';
import { InputTextElement } from '../elements/input-text.element';
import { OverflowScrollElement } from '../elements/overflow-scroll.element';
import { SpacerElement } from '../elements/spacer.element';
import { SpinnerElement } from '../elements/spinner.element';
import { UserTableTemplate } from '../templates/user-table.template';
import { CreateNewNoteFormTemplate } from '../templates/create-new-note-form.template';
import { groupNotesService } from '../../domain/group-notes/group-notes.service';

const dateEightWeeksAgo = new Date(
  Date.now() - 8 * 7 * 24 * 60 * 60 * 1000,
).toISOString();
const dateOneHourAgo = new Date(Date.now() - 1 * 60 * 60 * 1000).toISOString();

function MarkAttendanceScreen(): JSX.Element {
  const groupId = useSearchParam('group-id');
  const { onSubmitForm: onSubmitMarkAttendanceForm } = useForm();
  const { appendSuccess, appendError } = useNotificationContext();
  const [selectedUserIds, setSelectedUserIds] = useState<string[]>([]);
  const [selectedSessionDate, setSelectedSessionDate] = useState<string>(
    new Date().toISOString().substring(0, 10),
  );
  const navigate = useNavigate();

  // Fetch the group and its members
  const { useGetGroup, useGroupHttpOptions } = useGroupService();
  const getUseGroupHttpOptions = useGroupHttpOptions(
    function factory(): IHttpOptions {
      return {
        populate: ['members', 'location'],
        select: ['name', 'memberIds', 'locationId'],
      };
    },
    [],
  );
  const {
    document: group,
    isBusy: isGetGroupBusy,
    refresh: refreshGroup,
  } = useGetGroup(groupId, getUseGroupHttpOptions);

  // Fetch all the users
  const paginateGroupMembers = useCallback(
    async (skip: number, limit: number) => {
      const { data: users } = await userService.getAll({
        match: {
          // eslint-disable-next-line @typescript-eslint/naming-convention -- This is a special case.
          'groups._id': groupId,
        },
        addFields: {
          isInactive: {
            $cond: [
              {
                $lt: [
                  {
                    $ifNull: [
                      { $dateToString: { date: '$attendedAt' } },
                      new Date().toISOString(),
                    ],
                  },
                  dateEightWeeksAgo,
                ],
              },
              1,
              0,
            ],
          },
          isNew: {
            $cond: [
              {
                $gt: [
                  {
                    $ifNull: [
                      { $dateToString: { date: '$createdAt' } },
                      new Date().toISOString(),
                    ],
                  },
                  dateOneHourAgo,
                ],
              },
              1,
              0,
            ],
          },
          fullName: {
            $toLower: {
              $concat: ['$firstName', { $ifNull: ['$lastName', ''] }],
            },
          },
        },
        sort: ['isInactive', '-isNew', 'fullName', '_id'],
        offset: skip,
        limit,
      });
      return users;
    },
    [groupId],
  );

  const {
    items: users,
    nextPage,
    hasReachedLimit,
    isLoading: isLoadingUsers,
    reset: resetUsers,
  } = usePagination(paginateGroupMembers, 25);

  // When the form is submitted, mark the attendance.
  const handleOnSubmitMarkAttendanceForm = useCallback(
    async (formData: FormData) => {
      try {
        // Validate the existence of the group
        const locationId = group?.locationId;
        if (typeof locationId !== 'string') {
          throw new Error('No location for group');
        }
        // Get the selected attendance date from the form
        const attendedAt = new Date(`${formData.get('attendedAt')}`);
        if (
          attendedAt === null ||
          Number.isNaN(attendedAt.getTime()) === true ||
          attendedAt.getFullYear() < 1900
        ) {
          throw new Error(dictionary.literals.invalidDate);
        }
        // Get the selected users from the form
        const userIds = Array.from(formData.getAll('userIds[]')) as string[];
        if (userIds.length === 0) {
          throw new Error(dictionary.literals.noChildrenSelected);
        }
        // Bulk update the attendance
        await attendanceService.postSelectedUsers(
          locationId,
          attendedAt,
          ...userIds,
        );
        appendSuccess(dictionary.literals.attendanceMarkedSuccessfully);
        return function callback() {
          navigate(`/groups/${groupId}`, { replace: true });
        };
      } catch (error) {
        if (error instanceof Error) {
          appendError(error.message);
        }
      }
      return undefined;
    },
    [appendError, appendSuccess, group, groupId, navigate],
  );

  const { show: showCreateNewNoteSheet, close: closeCreateNewNoteSheet } =
    useSheetContext();

  const handleOnSubmitCreateNewNoteForm = useCallback(
    async (formData: FormData) => {
      try {
        // Check if group id is a string
        if (typeof groupId !== 'string') {
          throw new Error('No location for group');
        }

        // Add group id to form data
        formData.append('groupId', groupId);

        formData.append('sessionDate', selectedSessionDate);

        // Post new group note
        await groupNotesService.post(formData);
        // Add refresh group notes
        closeCreateNewNoteSheet();
        navigate(`/groups/${groupId}`, { replace: true });
        appendSuccess(dictionary.literals.noteCreatedSuccessfully);
      } catch {
        appendError(dictionary.literals.noteCreationFailed);
      }
    },
    [
      appendError,
      appendSuccess,
      closeCreateNewNoteSheet,
      groupId,
      navigate,
      selectedSessionDate,
    ],
  );

  const handleShowCreateNewNote = useCallback(() => {
    showCreateNewNoteSheet(
      <>
        <SpacerElement minimalLength={30} />
        <CreateNewNoteFormTemplate onSubmit={handleOnSubmitCreateNewNoteForm} />
      </>,
    );
  }, [handleOnSubmitCreateNewNoteForm, showCreateNewNoteSheet]);

  const { show: showRegisterChildSheet, close: closeRegisterChildSheet } =
    useSheetContext();

  // When the form is submitted, create a new user.
  const handleOnSubmitCreateUserForm = useCallback(
    async (formData: FormData) => {
      try {
        const { data: freshGroup } = await groupService.get(groupId ?? '');

        // Add locationId to the form data
        const locationId = freshGroup?.locationId;
        if (typeof locationId !== 'string') {
          throw new Error('No location for group');
        }
        formData.append('locationIds[]', locationId);
        // Add the role to the form data
        formData.append('role', Role.CHILD.toString());

        // Variable that holds the birth year of the user after it is calculated
        const age = formData.get('age') || null;
        if (age !== null) {
          const yearOfBirth = ageToYearOfBirth(+age);
          // Add the year of birth to the form data
          formData.append('yearOfBirth', yearOfBirth.toString());
        }
        // Remove the age from the form data
        formData.delete('age');

        // Create the user
        const userResponse = await userService.post(formData);

        setSelectedUserIds((prevSelectedUserIds) =>
          prevSelectedUserIds.concat([userResponse.data.id]),
        );

        // Add the user to the group
        const memberIds = (freshGroup?.memberIds ?? []).concat();
        memberIds.push(userResponse.data.id);
        await groupService.patch({
          id: groupId,
          memberIds,
        });
        // Refresh the group
        await resetUsers();
        await refreshGroup();
        // Close the sheet
        closeRegisterChildSheet();
        appendSuccess(dictionary.literals.childRegisteredSuccessfully);
      } catch {
        appendError(dictionary.texts.childRegistrationFailed);
      }
    },
    [
      appendError,
      appendSuccess,
      closeRegisterChildSheet,
      groupId,
      refreshGroup,
      resetUsers,
    ],
  );

  const handleShowCreateUserForm = useCallback(() => {
    showRegisterChildSheet(
      <RegisterChildFormTemplate onSubmit={handleOnSubmitCreateUserForm} />,
    );
  }, [handleOnSubmitCreateUserForm, showRegisterChildSheet]);

  useFloatingActionButtonContext({
    callback: handleShowCreateUserForm,
    content: dictionary.literals.registerNewChild,
  });

  if (group === null) {
    return (
      <ContainerElement>
        <FlexBoxElement>
          <SpacerElement />
          <SpinnerElement />
          <p>{dictionary.literals.loading}...</p>
        </FlexBoxElement>
      </ContainerElement>
    );
  }

  return (
    <ContainerElement>
      <BreadCrumbsElement
        crumbs={[
          dictionary.literals.home,
          dictionary.literals.groups,
          group.name,
          dictionary.literals.markAttendance,
        ]}
      />

      <SpacerElement />
      <h1>{dictionary.literals.markAttendance}</h1>
      <SpacerElement />
      {isGetGroupBusy === true && isLoadingUsers === true && (
        <Fragment>
          <p>{dictionary.literals.loading}...</p>
          <SpacerElement />
        </Fragment>
      )}
      <p>
        {dictionary.texts.isPartOf(group.name)}, {group.location?.name}
      </p>
      <SpacerElement />
      <CardElement>
        <h2>{dictionary.literals.selectChildren}</h2>
        <SpacerElement />
        <form
          onSubmit={onSubmitMarkAttendanceForm(
            handleOnSubmitMarkAttendanceForm,
          )}
        >
          <OverflowScrollElement>
            <UserTableTemplate
              canSelect
              users={users}
              selectedUserIds={selectedUserIds}
              onSelectedChildrenChange={setSelectedUserIds}
            />
          </OverflowScrollElement>
          {hasReachedLimit === false && group.memberIds.length > 25 && (
            <>
              <SpacerElement />
              <ActionButtonElement
                onClick={nextPage}
                flavour="secondary"
                children={dictionary.literals.showMore}
              />
            </>
          )}
          <SpacerElement />
          <InputTextElement
            name="attendedAt"
            placeholder={dictionary.literals.date}
            type="date"
            defaultValue={selectedSessionDate}
            onChange={setSelectedSessionDate}
          />
          <SpacerElement />
          <ActionButtonElement
            role="submitClosestForm"
            children={dictionary.literals.confirmAttendance}
          />
          <SpacerElement />
          <ActionButtonElement
            flavour="secondary"
            children={dictionary.literals.sessionIsCanceled}
            onClick={handleShowCreateNewNote}
          />
        </form>
        <SpacerElement />
      </CardElement>
    </ContainerElement>
  );
}

export { MarkAttendanceScreen };
