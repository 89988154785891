import { useEffect } from 'react';

// Effect hook to handle component lifecycle.
function useLifeCycle(
  mountEffect: () => void,
  unmountEffect?: () => void,
): void {
  return useEffect(function onMountEffect(): () => void {
    mountEffect();
    return function onUnountEffect(): void {
      if (unmountEffect !== undefined) {
        unmountEffect();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps -- Effect used component lifecycle.
  }, []);
}

export { useLifeCycle };
