import { useEffect } from 'react';

const scrollCorrection = 10;

/**
 * A hook which calls the provided callback when the user has scrolled to the
 * bottom of the provided element.
 * @param scrollElement
 * @param onBottomReached
 */
const useOnBottomReached = (
  /**
   * The callback which is called when the bottom of the scroll element is reached.
   */
  onBottomReached: () => unknown,

  /**
   * The element for which the scroll is observed.
   *
   * Defaults to the document.body.
   */
  scrollElement: HTMLElement | null = document.body,
): void => {
  useEffect(() => {
    const onScroll = () => {
      if (
        // if the body is the scroll target, use the window as scroll reference
        (scrollElement === document.body &&
          window.scrollY + window.innerHeight >=
            document.body.scrollHeight - scrollCorrection) ||
        // otherwise use the elements own scroll values
        (scrollElement !== document.body &&
          scrollElement &&
          scrollElement.scrollTop + scrollElement.clientHeight >=
            scrollElement.scrollHeight - scrollCorrection)
      ) {
        onBottomReached();
      }
    };

    // if the body has been set as scroll element, listen to the document. Listen
    // to the element itself otherwise.
    const listenerElement =
      scrollElement === document.body ? document : scrollElement;
    listenerElement?.addEventListener('scroll', onScroll);

    return () => {
      listenerElement?.removeEventListener('scroll', onScroll);
    };
  }, [onBottomReached, scrollElement]);
};

export { useOnBottomReached };
