import { DependencyList, useEffect } from 'react';

// An effect wrapper which simply allows you to use useEffect asynchronously.
function useEffectAsync(
  asyncEffect: () => Promise<void>,
  dependencyList: DependencyList,
): void {
  return useEffect(
    function effect() {
      asyncEffect();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps -- We cannot know if the dependency list is exhaustive
    dependencyList,
  );
}

export { useEffectAsync };
