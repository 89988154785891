import { useCallback, useRef, useState } from 'react';
import { useAuthenticationContext } from '../../domain/authentication/authentication-context.hook';
import { dictionary } from '../../domain/common/constants/dictionary.constants';
import { ActionButtonElement } from '../elements/action-button.element';
import { InputSelectElement } from '../elements/input-select.element';
import { InputTextAreaElement } from '../elements/input-textarea.element';
import { SpacerElement } from '../elements/spacer.element';

type CancelReason = 'nochildren' | 'notenoughfacilitators' | 'other';

type CancelReasonOption = {
  value: CancelReason;
  label: string;
};

const cancelReasonOptions: CancelReasonOption[] = [
  {
    value: 'notenoughfacilitators',
    label: dictionary.literals.notEnoughFacilitators,
  },
  {
    value: 'nochildren',
    label: dictionary.literals.noChildren,
  },
  {
    value: 'other',
    label: dictionary.literals.otherwiseNamely,
  },
];

const cancelReasonNotes: Record<CancelReason, string> = {
  notenoughfacilitators: dictionary.literals.notEnoughFacilitators,
  nochildren: dictionary.literals.noChildren,
  other: dictionary.literals.otherwiseNamely,
};

interface IProps {
  onSubmit: (formdata: FormData) => void | Promise<void>;
}

const CreateNewNoteFormTemplate = (props: IProps): JSX.Element => {
  const formRef = useRef<HTMLFormElement>(null);
  const { sessionToken } = useAuthenticationContext();

  const [selectedCancelReason, setSelectedCancelReason] =
    useState<CancelReason>('notenoughfacilitators');

  const handleClickSubmit = useCallback(() => {
    if (!formRef.current?.reportValidity() || !formRef.current) {
      return;
    }

    const formData = new FormData(formRef.current);
    if (selectedCancelReason === 'other') {
      formData.set(
        'body',
        [cancelReasonNotes.other, formData.get('body')].join(' - '),
      );
    }
    props.onSubmit(formData);
  }, [selectedCancelReason, props]);

  return (
    <>
      <h2>{dictionary.literals.giveAReason}</h2>
      <SpacerElement />
      <form ref={formRef}>
        <input type="hidden" name="authorId" value={sessionToken?.userId} />
        {selectedCancelReason !== 'other' && (
          <input
            type="hidden"
            name="body"
            value={cancelReasonNotes[selectedCancelReason]}
          />
        )}
        <InputSelectElement
          isRequired
          defaultValue={selectedCancelReason}
          placeholder={dictionary.literals.noReasonSelected}
          options={Object.values(cancelReasonOptions).map((option) => option)}
          onChange={(value) => setSelectedCancelReason(value as CancelReason)}
        />
        <SpacerElement />
        {selectedCancelReason === 'other' && (
          <InputTextAreaElement
            name="body"
            placeholder={dictionary.literals.enterYourMessage}
            autoFocus
            required
          />
        )}
        <SpacerElement />
        <ActionButtonElement
          role="submitClosestForm"
          children={dictionary.literals.confirm}
          onClick={handleClickSubmit}
        />
      </form>
    </>
  );
};

export { CreateNewNoteFormTemplate };
