import { useCrudService } from '../common/crud-service.hook';
import { groupService } from './group.service';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types -- Implicitly typed
function useGroupService() {
  const useGroupService = useCrudService(groupService);
  return {
    useGroupHttpOptions: useGroupService.useHttpOptions,
    useGetAllGroups: useGroupService.useGetAll,
    useGetGroup: useGroupService.useGet,
  };
}

export { useGroupService };
