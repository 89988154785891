import { NotificationFlavor } from '../../notification/notification.hook';

type IColors = {
  primary: string;
  secondary: string;
  dangerous: string;
  largeText: string;
  bodyText: string;
  shadow: string;
  notificationFlavors: {
    [key in NotificationFlavor]: string;
  };
};

const colors: IColors = {
  primary: '#1678F2',
  secondary: '#ACB1C7',
  dangerous: '#d00020',
  largeText: '#46486D',
  bodyText: '#B7B7BE',
  shadow: '#00000029',
  notificationFlavors: {
    message: '#1678F2',
    success: '#00C851',
    warning: '#FFAB00',
    error: '#FF5252',
  },
};

export { colors };
