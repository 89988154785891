import { IGroup } from '@hulanbv/teamup';
import { Fragment } from 'react';
import { useNavigate } from 'react-router';
import { dictionary } from '../../domain/common/constants/dictionary.constants';
import { useAuthenticationContext } from '../../domain/authentication/authentication-context.hook';
import { useLocationService } from '../../domain/location/location-service.hook';
import { useNotificationContext } from '../../domain/notification/notification-context.hook';
import { ActionButtonElement } from '../elements/action-button.element';
import { BreadCrumbsElement } from '../elements/bread-crumbs.element';
import { CardElement } from '../elements/card.element';
import { ContainerElement } from '../elements/container.element';
import { FlexBoxElement } from '../elements/flex-box.element';
import { SpacerElement } from '../elements/spacer.element';

function IndexScreen(): JSX.Element {
  const navigate = useNavigate();
  const { sessionToken, logout } = useAuthenticationContext();
  const { appendMessage } = useNotificationContext();

  // Fetch all locations.
  const { useGetAllLocations, useLocationHttpOptions } = useLocationService();
  const getAllLocationsHttpOptions = useLocationHttpOptions(function factory() {
    return { sort: ['name'], populate: ['groups'] };
  }, []);
  const { documents: locations, isBusy: isGetAllLocationsBusy } =
    useGetAllLocations(getAllLocationsHttpOptions);

  function handleOnClickGroup(group: IGroup) {
    navigate(`/groups/${group.id}`);
  }

  function handleOnClickMarkAttendance(group: IGroup) {
    navigate(`/mark-attendance?group-id=${group.id}`);
  }

  function handleOnClickLogout() {
    logout();
    navigate('/login');
    appendMessage(dictionary.literals.byeBye);
  }

  return (
    <ContainerElement>
      <BreadCrumbsElement crumbs={[dictionary.literals.home]} />
      <SpacerElement />
      <h1>{dictionary.literals.heyThere}!</h1>
      <SpacerElement />
      <p>
        {dictionary.literals.welcomeBack},{' '}
        {sessionToken?.user?.firstName ?? dictionary.literals.user}!{' '}
        {dictionary.literals.readyForTheDay}?
      </p>
      <SpacerElement />
      <h2>{dictionary.literals.yourLocationsAndGroups}</h2>
      <SpacerElement />
      {isGetAllLocationsBusy === true && (
        <Fragment>
          <p>{dictionary.literals.loading}...</p>
          <SpacerElement />
        </Fragment>
      )}
      {locations.map((location) => (
        <Fragment key={location.id}>
          <CardElement>
            <p>
              {dictionary.literals.locationIn} {location.city}
            </p>
            <h3>{location.name}</h3>
            {location.groups?.map((group) => (
              <Fragment key={group.id}>
                <SpacerElement />
                <CardElement>
                  <p>{dictionary.texts.aboutGroupSize(group)}</p>
                  <h4>{group.name}</h4>
                  <SpacerElement />
                  <FlexBoxElement mobileDirection="column">
                    <ActionButtonElement
                      onClick={() => handleOnClickGroup(group)}
                      children={dictionary.literals.viewGroupDetails}
                    />
                    <ActionButtonElement
                      onClick={() => handleOnClickMarkAttendance(group)}
                      children={dictionary.literals.markAttendance}
                    />
                  </FlexBoxElement>
                </CardElement>
              </Fragment>
            ))}
          </CardElement>
          <SpacerElement />
        </Fragment>
      ))}
      <p>{dictionary.literals.unableToFindWhatYoureLookingFor}?</p>
      <SpacerElement />
      <ActionButtonElement
        onClick={handleOnClickLogout}
        children={dictionary.literals.logout}
      />
    </ContainerElement>
  );
}

export { IndexScreen };
