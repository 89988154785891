import { useNavigate } from 'react-router';
import { stylesheet } from 'typestyle';
import { useAuthenticationContext } from '../../domain/authentication/authentication-context.hook';
import { colors } from '../../domain/common/constants/color.constants';
import { dictionary } from '../../domain/common/constants/dictionary.constants';
import { FlexBoxElement } from './flex-box.element';

function NavigationBarElement(): JSX.Element {
  const navigate = useNavigate();
  const { sessionToken } = useAuthenticationContext();

  function handleOnClickLogo() {
    if (sessionToken !== null) {
      navigate('/');
    } else {
      navigate('/login');
    }
  }

  return (
    <div className={classNames.navigationBar}>
      <FlexBoxElement justifyContent="space-between">
        <img
          onClick={handleOnClickLogo}
          src="/images/logo-croppped-mono-white.png"
          alt={dictionary.literals.title}
          className={classNames.logo}
        />
        <p className={classNames.subTitle}>
          {dictionary.literals.volunteerEnvironment}
        </p>
      </FlexBoxElement>
    </div>
  );
}

const classNames = stylesheet({
  navigationBar: {
    position: 'sticky',
    zIndex: 1,
    top: 0,
    width: '100%',
    padding: '22px 40px 20px 40px',
    backgroundColor: colors.primary,
    boxShadow: `0 5px 15px 0 ${colors.shadow}`,
  },
  logo: {
    height: '2.5rem',
    cursor: 'pointer',
  },
  subTitle: {
    color: 'white',
    textAlign: 'right',
  },
});

export { NavigationBarElement };
