import { IUser } from '@hulanbv/teamup';
import { useCallback, useEffect, useState } from 'react';
import { countryLabels } from '../../domain/common/constants/country-labels';
import { dictionary } from '../../domain/common/constants/dictionary.constants';
import { yearOfBirthToAge } from '../../domain/common/utilities/year-of-birth-to-age';
import { ActionButtonElement } from '../elements/action-button.element';
import { FlexBoxElement } from '../elements/flex-box.element';
import { InactivityIndicatorElement } from '../elements/inactivity-indicator-element';
import { InputCheckboxElement } from '../elements/input-checkbox.element';

interface IProps {
  users: IUser[];
  selectedUserIds?: string[];
  canSelect?: boolean;
  searchQuery?: string;
  rowActions?: {
    label: string;
    callback: (user: IUser) => void | Promise<void>;
  }[];
  onSelectedChildrenChange?: (selectedUserIds: string[]) => void;
}

const dateEightWeeksAgo = new Date(Date.now() - 8 * 7 * 24 * 60 * 60 * 1000);

function UserTableTemplate(props: IProps): JSX.Element {
  const { canSelect, searchQuery, rowActions } = props;
  const [selectedUserIds, setSelectedUserIds] = useState<string[]>(
    props.selectedUserIds ?? [],
  );

  useEffect(() => {
    setSelectedUserIds(props.selectedUserIds ?? []);
  }, [props.selectedUserIds]);

  const users = props.users.filter(function predicate(user) {
    if (typeof searchQuery === 'string' && searchQuery.length > 0) {
      return [user.firstName, user.lastName ?? '']
        .join(' ')
        .toLowerCase()
        .includes(searchQuery.toLowerCase());
    }
    return true;
  });

  const handleCheckboxToggle = useCallback(
    (value: string, isChecked: boolean) => {
      if (isChecked) {
        // Filters out the unchecked value from the userIds array.
        const userIds = selectedUserIds.filter((id) => id !== value);

        // Applies the filtered userIds to the state.
        props.onSelectedChildrenChange?.(userIds);
        setSelectedUserIds(userIds);
      } else {
        // Adds the checked value to the userIds array.
        const userIds = selectedUserIds.concat(value);

        // Applies the userIds array to the state.
        props.onSelectedChildrenChange?.(userIds);
        setSelectedUserIds(userIds);
      }
    },
    [props, selectedUserIds],
  );

  return (
    <table>
      <thead>
        <tr>
          {canSelect === true && <th />}
          {rowActions?.map((rowAction, i) => (
            <th key={i} />
          ))}
          <th>{dictionary.literals.name}</th>
          <th>{dictionary.literals.gender}</th>
          <th>{dictionary.literals.estAge}</th>
          <th>{dictionary.literals.countryOfOrigin}</th>
        </tr>
      </thead>
      <tbody>
        {users.map((user) => (
          <tr key={user.id}>
            {canSelect === true && (
              <td>
                <InputCheckboxElement
                  name="userIds[]"
                  value={user.id}
                  isChecked={selectedUserIds.includes(user.id)}
                  onCheckboxToggle={handleCheckboxToggle}
                />
              </td>
            )}
            {rowActions?.map((rowAction, index) => (
              <td key={index}>
                <ActionButtonElement
                  isSmall
                  children={rowAction.label}
                  onClick={() => rowAction.callback(user)}
                />
              </td>
            ))}
            <td>
              <FlexBoxElement justifyContent="flex-start" gap={5}>
                {user.attendedAt &&
                  new Date(user.attendedAt) <= dateEightWeeksAgo && (
                    <InactivityIndicatorElement />
                  )}
                <p>
                  {user.firstName} {user.lastName}
                </p>
              </FlexBoxElement>
            </td>
            <td>{dictionary.abbreviations.gender(user.gender)}</td>
            <td>
              {yearOfBirthToAge(user.yearOfBirth) ??
                dictionary.literals.unknown}
            </td>
            <td>
              {countryLabels[user.countryOfOrigin ?? ''] ??
                dictionary.literals.notSpecified}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export { UserTableTemplate };
