import { useNavigate } from 'react-router-dom';
import { dictionary } from '../../domain/common/constants/dictionary.constants';
import { useAuthenticationContext } from '../../domain/authentication/authentication-context.hook';
import { useForm } from '../../domain/form/form.hook';
import { useNotificationContext } from '../../domain/notification/notification-context.hook';
import { ActionButtonElement } from '../elements/action-button.element';
import { CardElement } from '../elements/card.element';
import { ContainerElement } from '../elements/container.element';
import { SpacerElement } from '../elements/spacer.element';
import { InputTextElement } from '../elements/input-text.element';
import { AnchorElement } from '../elements/anchor.element';
import { FlexBoxElement } from '../elements/flex-box.element';

function LoginScreen(): JSX.Element {
  const navigate = useNavigate();
  const { login } = useAuthenticationContext();
  const { onSubmitForm: onSubmit, isBusy } = useForm();
  const { appendError, appendSuccess } = useNotificationContext();

  async function handleOnSubmit(
    formData: FormData,
    htmlFormElement: HTMLFormElement,
  ): Promise<(() => void) | void> {
    try {
      await login(formData);
      return function callback() {
        navigate('/', { replace: true });
        appendSuccess(dictionary.literals.welcomeBack);
      };
    } catch (error) {
      appendError(dictionary.texts.loginFailed);
    }
    return undefined;
  }

  return (
    <ContainerElement>
      <h1>Login</h1>
      <SpacerElement />
      <p>{dictionary.literals.welcomeBack}!</p>
      <SpacerElement />
      <CardElement>
        <form onSubmit={onSubmit(handleOnSubmit)}>
          <InputTextElement
            name="email"
            type="email"
            placeholder={dictionary.literals.email}
            required
          />
          <SpacerElement />
          <InputTextElement
            name="password"
            type="password"
            placeholder={dictionary.literals.password}
            required
          />
          <SpacerElement />
          <ActionButtonElement
            role="submitClosestForm"
            children={
              isBusy === true
                ? dictionary.literals.loading
                : dictionary.literals.login
            }
          />
          <SpacerElement />
          <FlexBoxElement>
            <AnchorElement
              children={`${dictionary.literals.forgotPassword}?`}
              href="/forgot-password"
            />
          </FlexBoxElement>
        </form>
      </CardElement>
    </ContainerElement>
  );
}

export { LoginScreen };
