import { useCallback, useState } from 'react';

export type NotificationFlavor = 'message' | 'success' | 'warning' | 'error';

interface INotification {
  identifier: number;
  message: string;
  flavor: NotificationFlavor;
}

interface INotificationHook {
  appendMessage: (message: string) => void;
  appendSuccess: (message: string) => void;
  appendWarning: (message: string) => void;
  appendError: (message: string) => void;
  notifications: INotification[];
}

let currentNotificationIdentifier = 0;

function useNotification(): INotificationHook {
  const [notifications, setNotifications] = useState<INotification[]>([]);

  const append = useCallback((message: string, flavor: NotificationFlavor) => {
    currentNotificationIdentifier += 1;
    const identifier = currentNotificationIdentifier;
    setNotifications(function set(previousNotifications) {
      return [{ identifier, message, flavor }, ...previousNotifications];
    });
    window.setTimeout(function onTimeout() {
      setNotifications(function set(previousNotifications) {
        return previousNotifications.filter(function filter(notification) {
          return notification.identifier !== identifier;
        });
      });
    }, 5000);
  }, []);

  const appendMessage = useCallback(
    (message: string) => {
      append(message, 'message');
    },
    [append],
  );

  const appendSuccess = useCallback(
    (message: string) => {
      append(message, 'success');
    },
    [append],
  );

  const appendWarning = useCallback(
    (message: string) => {
      append(message, 'warning');
    },
    [append],
  );

  const appendError = useCallback(
    (message: string) => {
      append(message, 'error');
    },
    [append],
  );

  return {
    appendMessage,
    appendSuccess,
    appendWarning,
    appendError,
    notifications,
  };
}

export { useNotification };
