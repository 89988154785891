import { cssRule, fontFace } from 'typestyle';
import { colors } from '../domain/common/constants/color.constants';

fontFace({
  fontFamily: 'DM Sans',
  fontStyle: 'normal',
  fontWeight: 400,
  src: 'url(/fonts/dmsans-regular.ttf)',
});

fontFace({
  fontFamily: 'DM Sans',
  fontStyle: 'normal',
  fontWeight: 700,
  src: 'url(/fonts/dmsans-bold.ttf)',
});

fontFace({
  fontFamily: 'Oli Grotesk',
  fontStyle: 'normal',
  fontWeight: 700,
  src: 'url(/fonts/oli-grotesk-bold.woff2)',
});

cssRule('*', {
  boxSizing: 'border-box',
  margin: 0,
  padding: 0,
  // The following properties are used to indicate unstyled elements.
  fontFamily: 'Courier New',
  color: 'magenta',
});

cssRule('html', {
  fontSize: 16,
});

cssRule('body', {
  backgroundColor: '#f6f6f9',
});

cssRule('h1, h2, h3, h4, h5, h6', {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

cssRule('h1', {
  fontFamily: '"DM Sans", sans-serif',
  color: colors.largeText,
  fontSize: '2.5rem',
  fontWeight: 400,
});

cssRule('h2, th', {
  fontFamily: '"Oli Grotesk", sans-serif',
  color: colors.largeText,
  fontSize: '1.15rem',
  textTransform: 'uppercase',
  fontWeight: 700,
});

cssRule('h3', {
  fontFamily: '"DM Sans", sans-serif',
  color: colors.largeText,
  fontSize: '2rem',
  fontWeight: 400,
});

cssRule('h4', {
  fontFamily: '"DM Sans", sans-serif',
  color: colors.largeText,
  fontSize: '1.4rem',
  fontWeight: 700,
});

cssRule('h6', {
  fontFamily: '"DM Sans", sans-serif',
  color: colors.bodyText,
  fontSize: '1rem',
  fontWeight: 400,
});

cssRule('table', {
  borderCollapse: 'collapse',
});

cssRule('p, td', {
  fontFamily: '"DM Sans", sans-serif',
  fontWeight: 'bold',
  color: colors.bodyText,
});

// Table data cells and table headers
cssRule('td, th', {
  // Spacing around each element so columns don't end up side by side.
  paddingRight: 40,
  paddingTop: 5,
  paddingBottom: 5,
  paddingLeft: 10,
  // Elements should not wrap, allowing the table to overflow.
  whiteSpace: 'nowrap',
  textAlign: 'left',
  borderBottom: '5px solid #f6f6f9',
});
