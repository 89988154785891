import { IGroup } from '@hulanbv/teamup';
import { CrudService } from 'nest-utilities-client';
import { environment } from '../common/constants/environment.constants';
import { httpService } from '../common/http.service';

class GroupService extends CrudService<IGroup> {
  constructor() {
    super([environment.REACT_APP_API_URL, 'groups'].join('/'), httpService);
  }
}

const groupService = new GroupService();

export { groupService };
