import { Gender } from '@hulanbv/teamup';
import { Fragment, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router';
import { countryLabels } from '../../domain/common/constants/country-labels';
import { dictionary } from '../../domain/common/constants/dictionary.constants';
import { useSearchParam } from '../../domain/common/search-param.hook';
import { ageToYearOfBirth } from '../../domain/common/utilities/age-to-year-of-birth';
import { mapEnumKeys } from '../../domain/common/utilities/map-enum-keys';
import { yearOfBirthToAge } from '../../domain/common/utilities/year-of-birth-to-age';
import { useForm } from '../../domain/form/form.hook';
import { useNotificationContext } from '../../domain/notification/notification-context.hook';
import { useUserService } from '../../domain/user/user-service.hook';
import { userService } from '../../domain/user/user.service';
import { ActionButtonElement } from '../elements/action-button.element';
import { BreadCrumbsElement } from '../elements/bread-crumbs.element';
import { CardElement } from '../elements/card.element';
import { ContainerElement } from '../elements/container.element';
import { InputSelectElement } from '../elements/input-select.element';
import { InputTextElement } from '../elements/input-text.element';
import { SpacerElement } from '../elements/spacer.element';

function EditUserScreen(): JSX.Element {
  const navigate = useNavigate();
  const { onSubmitForm } = useForm();
  const { useGetUser } = useUserService();
  const { appendSuccess, appendError } = useNotificationContext();
  const { userId } = useParams();
  const groupId = useSearchParam('group-id');
  const { document: user, isBusy: isGetUserBusy } = useGetUser(userId);

  // Allows the user to leave this view, this will either navigate them back to
  // the index view, or return them back to the specific group they were viewing
  // based on the groupId param.
  const leaveView = useCallback(() => {
    if (groupId !== null) {
      // If a group ID search parameter is provided, we'll navigate back to
      // the groups view with the provided ID.
      navigate(`/groups/${groupId}`, { replace: true });
    } else {
      // Otherwise we'll navigate back to the homepage.
      navigate('/', { replace: true });
    }
  }, [groupId, navigate]);

  // Event invoked when the user submits the edit use form.
  const handleOnSubmitForm = useCallback(
    async (formData: FormData): Promise<void> => {
      try {
        // Variable that holds the birth year of the user after it is calculated
        const age = formData.get('age') || null;
        if (age !== null) {
          const yearOfBirth = ageToYearOfBirth(+age);
          // Add the year of birth to the form data
          formData.append('yearOfBirth', yearOfBirth.toString());
        }
        // Remove the age from the form data
        formData.delete('age');

        // The form data is patched, this updates the user.
        await userService.patch(formData);
        appendSuccess(dictionary.literals.childEditedSuccessfully);
        leaveView();
      } catch {
        appendError(dictionary.texts.childEditingFailed);
      }
    },
    [appendError, appendSuccess, leaveView],
  );

  const handleOnClickDelete = useCallback(async () => {
    try {
      // HACK -- Implement an actual confirmation dialog.
      // eslint-disable-next-line no-alert -- This is a temporary solution.
      const didConfirm = window.confirm(dictionary.texts.sureDeleteChild);
      if (didConfirm === true) {
        // The user will be deleted from the system.
        await userService.delete(user?._id);
        appendSuccess(dictionary.literals.childDeletedSuccessfully);
        leaveView();
      }
    } catch {
      appendError(dictionary.texts.childDeletionFailed);
    }
  }, [appendError, appendSuccess, leaveView, user?._id]);

  return (
    <ContainerElement>
      <BreadCrumbsElement
        crumbs={[
          dictionary.literals.home,
          dictionary.literals.children,
          user?.firstName ?? '',
          dictionary.literals.editChildDetails,
        ]}
      />
      <SpacerElement />
      <h1>{dictionary.literals.editChildDetails}</h1>
      <SpacerElement />
      {isGetUserBusy === true && (
        <Fragment>
          <p>{dictionary.literals.loading}...</p>
          <SpacerElement />
        </Fragment>
      )}
      {user !== null && (
        <CardElement>
          <p>{dictionary.literals.editDetailsOf(user.firstName)}</p>
          <h3>{dictionary.literals.editChildDetails}</h3>
          <SpacerElement />
          <form onSubmit={onSubmitForm(handleOnSubmitForm)}>
            <input name="_id" value={user._id} type="hidden" />
            <InputTextElement
              name="firstName"
              placeholder={dictionary.literals.firstName}
              defaultValue={user?.firstName}
              required
            />
            <SpacerElement />
            <InputTextElement
              name="lastName"
              placeholder={dictionary.literals.lastName}
              defaultValue={user?.lastName}
              required
            />
            <SpacerElement />
            <InputTextElement
              name="age"
              type="number"
              placeholder={dictionary.literals.estAge}
              defaultValue={yearOfBirthToAge(user?.yearOfBirth) ?? undefined}
            />
            <SpacerElement />
            <InputSelectElement
              name="gender"
              placeholder={dictionary.literals.gender}
              defaultValue={user.gender?.toString()}
              options={mapEnumKeys(Gender, (key) => ({
                label: dictionary.abbreviations.gender(Gender[key]),
                value: Gender[key].toString(),
              }))}
            />
            <SpacerElement />
            <InputSelectElement
              name="countryOfOrigin"
              placeholder={dictionary.literals.countryOfOrigin}
              defaultValue={user.countryOfOrigin}
              isSearchable={true}
              emptyOptionLabel={dictionary.literals.notSpecified}
              options={Object.keys(countryLabels).map((key) => ({
                label: countryLabels[key],
                value: key,
              }))}
            />
            <SpacerElement />
            <ActionButtonElement
              role="submitClosestForm"
              children={dictionary.literals.applyChanges}
            />
            {groupId !== null && (
              <Fragment>
                <SpacerElement />
                <ActionButtonElement
                  onClick={handleOnClickDelete}
                  children={dictionary.literals.deleteChild}
                  flavour="dangerous"
                />
              </Fragment>
            )}
          </form>
        </CardElement>
      )}
    </ContainerElement>
  );
}

export { EditUserScreen };
