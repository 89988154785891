import { ICredentials, ISessionToken } from '@hulanbv/teamup';
import { IHttpOptions, IResponse } from 'nest-utilities-client';
import { useCallback, useState } from 'react';
import { httpService } from '../common/http.service';
import { authenticationService } from './authentication.service';

interface IUseAuthenticationHook {
  login: (
    credentials: ICredentials | FormData,
    options?: IHttpOptions<ISessionToken>,
  ) => Promise<IResponse<ISessionToken, null>>;
  logout: (
    options?: IHttpOptions<ISessionToken>,
  ) => Promise<IResponse<void, null>>;
  validate: (
    options?: IHttpOptions<ISessionToken>,
  ) => Promise<IResponse<ISessionToken, null>>;
  sessionToken: ISessionToken | null;
  isAuthenticated: boolean;
}

function useAuthentication(): IUseAuthenticationHook {
  const [sessionToken, setSessionToken] = useState<ISessionToken | null>(null);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);

  const login = useCallback(async function on(
    credentials: ICredentials | FormData,
    options?: IHttpOptions,
  ) {
    try {
      const response = await authenticationService.login(credentials, options);
      setSessionToken(response.data);
      setIsAuthenticated(true);
      return response;
    } catch (error) {
      setSessionToken(null);
      setIsAuthenticated(false);
      throw error;
    }
  },
  []);

  const validate = useCallback(async function on(options?: IHttpOptions) {
    try {
      const response = await authenticationService.validate(options);
      setSessionToken(response.data);
      setIsAuthenticated(true);
      return response;
    } catch (error) {
      setSessionToken(null);
      setIsAuthenticated(false);
      throw error;
    }
  }, []);

  const logout = useCallback(async function on(options?: IHttpOptions) {
    const response = await authenticationService.logout(options);
    setSessionToken(null);
    setIsAuthenticated(false);
    return response;
  }, []);

  const hook = {
    login,
    logout,
    validate,
    sessionToken,
    isAuthenticated,
  };

  // Inform the HTTP service about the new current authentication hook.
  httpService.currentAuthenticationHook = hook;

  return hook;
}

export { useAuthentication };
