import { dictionary } from '../../domain/common/constants/dictionary.constants';
import { AnchorElement } from '../elements/anchor.element';
import { CardElement } from '../elements/card.element';
import { ContainerElement } from '../elements/container.element';
import { FlexBoxElement } from '../elements/flex-box.element';
import { SpacerElement } from '../elements/spacer.element';
import { ForgotPasswordFormTemplate } from '../templates/forgot-password-form.template';

const ForgotPasswordScreen = (): JSX.Element => (
  <ContainerElement>
    <h1>{dictionary.literals.forgotPassword}</h1>
    <SpacerElement />
    <p>{dictionary.literals.enterYourEmailaddress}</p>
    <SpacerElement />
    <CardElement>
      <ForgotPasswordFormTemplate />
      <SpacerElement />
      <FlexBoxElement>
        <AnchorElement
          children={dictionary.literals.backTologin}
          href="/login"
        />
      </FlexBoxElement>
    </CardElement>
  </ContainerElement>
);

export { ForgotPasswordScreen };
