import { keyframes, stylesheet } from 'typestyle';
import { colors } from '../common/constants/color.constants';
import { useNotificationContext } from './notification-context.hook';

function NotificationRenderer(): JSX.Element {
  const { notifications } = useNotificationContext();

  return (
    <div className={classNames.container}>
      {notifications.map((notification) => (
        <div
          className={classNames.notification}
          key={notification.identifier}
          style={{
            borderLeft: `1em solid ${
              colors.notificationFlavors[notification.flavor]
            }`,
          }}
        >
          <p className={classNames.message}>{notification.message}</p>
        </div>
      ))}
    </div>
  );
}

const fadeInAnimationName = keyframes({
  '0%': {
    transform: 'translateY(10px)',
    opacity: 0,
  },
  '100%': {
    transform: 'translateY(0)',
    opacity: 1,
  },
});

const classNames = stylesheet({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    gap: 10,
    width: 'auto',
  },
  notification: {
    backgroundColor: '#ffffff',
    borderRadius: 10,
    boxShadow: '0 10px 20px 0 #00000033',
    padding: 20,
    animationName: fadeInAnimationName,
    animationDuration: '0.3s',
    pointerEvents: 'auto',
  },
  message: {
    color: '#444',
  },
});

export { NotificationRenderer };
