import { IGroupNote } from '@hulanbv/teamup';
import { CrudService } from 'nest-utilities-client';
import { environment } from '../common/constants/environment.constants';
import { httpService } from '../common/http.service';

class GroupNotesService extends CrudService<IGroupNote> {
  constructor() {
    super(
      [environment.REACT_APP_API_URL, 'group-notes'].join('/'),
      httpService,
    );
  }
}

const groupNotesService = new GroupNotesService();

export { groupNotesService };
