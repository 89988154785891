import { useCrudService } from '../common/crud-service.hook';
import { attendanceService } from './attendance.service';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types -- Implicitly typed
function useAttendanceService() {
  const useAttendanceService = useCrudService(attendanceService);
  return {
    useAttendanceHttpOptions: useAttendanceService.useHttpOptions,
    useGetAllAttendances: useAttendanceService.useGetAll,
    useGetAttendance: useAttendanceService.useGet,
  };
}

export { useAttendanceService };
