import { MouseEvent, useMemo } from 'react';
import { classes, stylesheet } from 'typestyle';
import { colors } from '../../domain/common/constants/color.constants';

interface IProps {
  onClick?: () => void;
  role?: 'submitClosestForm';
  children: string;
  isSmall?: boolean;
  flavour?: 'dangerous' | 'secondary';
  isFullWidth?: boolean;
  isDisabled?: boolean;
  hasDarkShadow?: boolean;
}

function ActionButtonElement(props: IProps): JSX.Element {
  const {
    onClick,
    role,
    children,
    isSmall,
    isFullWidth = true,
    flavour,
    hasDarkShadow,
  } = props;

  const handleOnClick = (mouseEvent: MouseEvent<HTMLDivElement>) => {
    if (role === 'submitClosestForm') {
      const formElement = mouseEvent.currentTarget.closest('form');
      if (formElement?.reportValidity()) {
        const event = new Event('submit', { cancelable: true, bubbles: true });
        formElement.dispatchEvent(event);
      }
    }
    onClick?.();
  };

  const buttonClassNames = useMemo(() => {
    const buttonClassNames: (string | false)[] = [
      classNames.button,
      isSmall === true && classNames.smallButton,
      flavour === 'dangerous' && classNames.dangerousButtonFlavour,
      flavour === 'secondary' && classNames.secondaryButtonFlavour,
      hasDarkShadow === true && classNames.darkShadow,
      isFullWidth === false && classNames.fullWidth,
    ];
    return buttonClassNames.join(' ');
  }, [isSmall, flavour, hasDarkShadow, isFullWidth]);

  return (
    <div
      className={classes(
        buttonClassNames,
        props.isDisabled && classNames.disabled,
      )}
      onClick={handleOnClick}
    >
      <p className={classNames.text}>{children}</p>
    </div>
  );
}

const classNames = stylesheet({
  button: {
    position: 'relative',
    width: '100%',
    padding: 20,
    backgroundColor: colors.primary,
    borderRadius: 10,
    boxShadow: `0 10px 20px 0 ${colors.primary}55`,
    cursor: 'pointer',
    transition: 'background-color 0.1s ease-in-out',
    $nest: {
      '&:hover': {
        $nest: {
          '&::after': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            backgroundColor: '#ffffff66',
            borderRadius: 10,
          },
        },
      },
      '&:active': {
        backgroundColor: `${colors.primary}77`,
      },
    },
  },
  smallButton: {
    paddingTop: 5,
    paddingBottom: 5,
  },
  dangerousButtonFlavour: {
    backgroundColor: colors.dangerous,
    boxShadow: `0 10px 20px 0 ${colors.dangerous}55`,
    $nest: {
      '&:hover': {
        backgroundColor: `${colors.dangerous}aa`,
      },
      '&:active': {
        backgroundColor: `${colors.dangerous}77`,
      },
    },
  },
  secondaryButtonFlavour: {
    backgroundColor: colors.secondary,
    boxShadow: `0 10px 20px 0 ${colors.secondary}85`,
    $nest: {
      '&:hover': {
        backgroundColor: `${colors.secondary}aa`,
      },
      '&:active': {
        backgroundColor: `${colors.secondary}77`,
      },
    },
  },
  darkShadow: {
    boxShadow: '0 5px 15px 0 #00000055',
  },
  fullWidth: {
    width: 'auto',
  },
  text: {
    color: 'white',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  disabled: {
    backgroundColor: '#e0e0e0',
    pointerEvents: 'none',
    cursor: 'default',
  },
});

export { ActionButtonElement };
