import { BrowserRouter } from 'react-router-dom';
import { AuthenticationContext } from '../domain/authentication/authentication.context';
import { useAuthentication } from '../domain/authentication/authentication.hook';
import { FloatingActionButtonContext } from '../domain/floating-action-button/floating-action-button.context';
import { useFloatingActionButton } from '../domain/floating-action-button/floating-action-button.hook';
import { NotificationContext } from '../domain/notification/notification.context';
import { useNotification } from '../domain/notification/notification.hook';
import { SheetRenderer } from '../domain/sheet/sheet-renderer.component';
import { SheetContext } from '../domain/sheet/sheet.context';
import { useSheet } from '../domain/sheet/sheet.hook';
import { Application } from './application';

// First entry point where the application is bootstrapped.
export function Main(): JSX.Element {
  const authenticationHook = useAuthentication();
  const notificationHook = useNotification();
  const sheetHook = useSheet();
  const floatingActionButtonHook = useFloatingActionButton();

  return (
    <AuthenticationContext.Provider value={authenticationHook}>
      <NotificationContext.Provider value={notificationHook}>
        <SheetContext.Provider value={sheetHook}>
          <FloatingActionButtonContext.Provider
            value={floatingActionButtonHook}
          >
            <BrowserRouter>
              <Application />
              <SheetRenderer />
            </BrowserRouter>
          </FloatingActionButtonContext.Provider>
        </SheetContext.Provider>
      </NotificationContext.Provider>
    </AuthenticationContext.Provider>
  );
}
