import {
  ICredentials,
  IForgotPasswordRedemption,
  IForgotPasswordRequest,
  ISessionToken,
} from '@hulanbv/teamup';
import { CrudService, IHttpOptions, IResponse } from 'nest-utilities-client';
import { environment } from '../common/constants/environment.constants';
import { httpService } from '../common/http.service';

class AuthenticationService extends CrudService<ISessionToken> {
  private readonly sessionStorageKey = 'sessionToken';

  constructor() {
    super(
      [environment.REACT_APP_API_URL, 'authentication'].join('/'),
      httpService,
    );
  }

  async login(
    credentials: ICredentials | FormData,
    options?: IHttpOptions,
  ): Promise<IResponse<ISessionToken>> {
    const response = await this.http.post(this.controller, credentials, {
      ...options,
      populate: ['user', ...(options?.populate ?? [])],
    });
    this.storeSession(response.data);
    return response;
  }

  async logout(options?: IHttpOptions): Promise<IResponse<void>> {
    const response = await this.http.post(
      [this.controller, 'logout'].join('/'),
      null,
      options,
    );
    this.storeSession(null);
    return response;
  }

  async forgotPassword(
    body: IForgotPasswordRequest | FormData,
  ): Promise<IResponse<IForgotPasswordRequest>> {
    return this.http.post([this.controller, 'forgot-password'].join('/'), body);
  }

  async redeemPassword(
    body: IForgotPasswordRedemption | FormData,
  ): Promise<IResponse<IForgotPasswordRedemption>> {
    return this.http.post(
      [this.controller, 'forgot-password', 'redeem'].join('/'),
      body,
    );
  }

  async validate(options?: IHttpOptions): Promise<IResponse<ISessionToken>> {
    const response = await this.http.post(
      [this.controller, 'validate'].join('/'),
      null,
      {
        ...options,
        populate: ['user', ...(options?.populate ?? [])],
      },
    );
    this.storeSession(response.data);
    return response;
  }

  storeSession(session: ISessionToken | null) {
    if (session !== null) {
      localStorage.setItem(this.sessionStorageKey, JSON.stringify(session));
    } else {
      localStorage.removeItem(this.sessionStorageKey);
    }
  }

  getSession(): ISessionToken | null {
    try {
      const rawSession = localStorage.getItem(this.sessionStorageKey) ?? '';
      return JSON.parse(rawSession) || null;
    } catch {
      return null;
    }
  }
}

const authenticationService = new AuthenticationService();

export { authenticationService };
