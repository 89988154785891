import { CSSProperties, ReactNode, useMemo } from 'react';
import { stylesheet, media } from 'typestyle';

interface IProps {
  children: ReactNode;
  direction?: 'row' | 'column';
  mobileDirection?: 'row' | 'column';
  justifyContent?:
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'space-between'
    | 'space-around';
  alignItems?: 'flex-start' | 'flex-end' | 'center' | 'stretch' | 'baseline';
  gap?: number;
}

function FlexBoxElement(props: IProps): JSX.Element {
  const style = useMemo<CSSProperties>(
    function factory() {
      return {
        justifyContent: props.justifyContent || 'center',
        alignItems: props.alignItems || 'center',
        gap: props.gap || 20,
      };
    },
    [props],
  );

  const className = useMemo<string>(
    function factory() {
      const className = [classNames.container];
      if (props.direction === 'row') {
        className.push(classNames.containerDirectionRow);
      } else if (props.direction === 'column') {
        className.push(classNames.containerDirectionColumn);
      }
      if (props.mobileDirection === 'row') {
        className.push(classNames.containerMobileDirectionRow);
      } else if (props.mobileDirection === 'column') {
        className.push(classNames.containerMobileDirectionColumn);
      }
      return className.join(' ');
    },
    [props],
  );

  return <div className={className} style={style} children={props.children} />;
}

const classNames = stylesheet({
  container: {
    display: 'flex',
  },
  containerDirectionRow: {
    flexDirection: 'row',
  },
  containerDirectionColumn: {
    flexDirection: 'column',
  },
  containerMobileDirectionRow: media(
    { maxWidth: '600px' },
    { flexDirection: 'row' },
  ),
  containerMobileDirectionColumn: media(
    { maxWidth: '600px' },
    { flexDirection: 'column' },
  ),
});

export { FlexBoxElement };
