import { Fragment, useEffect, useState } from 'react';
import { stylesheet } from 'typestyle';
import { colors } from '../../domain/common/constants/color.constants';
import { FlexBoxElement } from './flex-box.element';

interface IProps {
  name: string;
  value: string;
  children?: string;
  isChecked?: boolean;
  onCheckboxToggle?: (value: string, isChecked: boolean) => void;
}

function InputCheckboxElement(props: IProps): JSX.Element {
  const { name, value, children } = props;
  const [isChecked, isSetChecked] = useState<boolean>(false);

  useEffect(() => {
    isSetChecked(props.isChecked ?? false);
  }, [props.isChecked]);

  function handleOnClickToggle() {
    isSetChecked(!isChecked);
    props.onCheckboxToggle?.(value, isChecked);
  }

  return (
    <Fragment>
      <input
        className={classNames.input}
        type="checkbox"
        name={name}
        value={value}
        checked={isChecked}
        onChange={handleOnClickToggle}
      />
      <div onClick={handleOnClickToggle} className={classNames.container}>
        <FlexBoxElement justifyContent="flex-start">
          <div className={classNames.checkbox}>
            {isChecked === true && <div className={classNames.checkboxInner} />}
          </div>
          {typeof children !== 'undefined' && <p>{children}</p>}
        </FlexBoxElement>
      </div>
    </Fragment>
  );
}

const classNames = stylesheet({
  input: {
    display: 'none',
  },
  container: {
    cursor: 'pointer',
  },
  checkbox: {
    width: '20px',
    height: '20px',
    border: '2px solid #ccc',
    borderRadius: '4px',
    backgroundColor: '#fff',
    overflow: 'hidden',
    transition: 'background-color 0.1s ease-in-out',
    $nest: {
      '&:hover': {
        opacity: 0.8,
      },
      '&:active': {
        opacity: 0.6,
      },
    },
  },
  checkboxInner: {
    marginTop: '15%',
    marginLeft: '15%',
    width: '70%',
    height: '70%',
    background: colors.primary,
    borderRadius: '2px',
  },
});

export { InputCheckboxElement };
