import { Fragment, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { dictionary } from '../../domain/common/constants/dictionary.constants';
import { useOnBottomReached } from '../../domain/common/use-on-bottom-reached.hook';
import { usePagination } from '../../domain/common/use-pagination.hook';
import { groupNotesService } from '../../domain/group-notes/group-notes.service';
import { useGroupService } from '../../domain/group/group-service.hook';
import { BreadCrumbsElement } from '../elements/bread-crumbs.element';
import { ContainerElement } from '../elements/container.element';
import { FlexBoxElement } from '../elements/flex-box.element';
import { GroupNoteElement } from '../elements/group-note-element';
import { SpacerElement } from '../elements/spacer.element';
import { SpinnerElement } from '../elements/spinner.element';

const GroupNotesScreen = (): JSX.Element => {
  const { groupId } = useParams();

  // Fetch the group
  const { useGetGroup } = useGroupService();
  const { document: group } = useGetGroup(groupId);

  const paginateGroupNotes = useCallback(
    async (skip: number, limit: number) => {
      const { data: groupNotes } = await groupNotesService.getAll({
        match: {
          groupId,
        },
        populate: ['author', 'group'],
        sort: ['-createdAt'],
        offset: skip,
        limit,
      });
      return groupNotes;
    },
    [groupId],
  );
  const {
    items: groupNotes,
    nextPage,
    isLoading: isLoadingGroupNotes,
  } = usePagination(paginateGroupNotes, 10);
  useOnBottomReached(nextPage);

  if (group === null) {
    return (
      <ContainerElement>
        <FlexBoxElement>
          <SpacerElement />
          <SpinnerElement />
          <p>{dictionary.literals.loading}...</p>
        </FlexBoxElement>
      </ContainerElement>
    );
  }

  return (
    <ContainerElement>
      <BreadCrumbsElement
        crumbs={[
          dictionary.literals.home,
          dictionary.literals.groups,
          group.name,
          dictionary.literals.canceledSessions,
        ]}
      />
      <SpacerElement />
      <h1>{dictionary.literals.canceledSessions}</h1>
      <SpacerElement />
      {groupNotes.length > 0 && (
        <p>{dictionary.texts.cancelationsOfGroupX(group.name)}</p>
      )}
      <SpacerElement />
      {groupNotes.length === 0 && (
        <>
          <FlexBoxElement>
            <p>{dictionary.literals.noSessionsHaveBeenCanceled}</p>
          </FlexBoxElement>
          <SpacerElement />
        </>
      )}
      {groupNotes.map((groupNote) => (
        <Fragment key={groupNote._id}>
          <GroupNoteElement groupNote={groupNote} />
          <SpacerElement />
        </Fragment>
      ))}
      {isLoadingGroupNotes === true && (
        <FlexBoxElement>
          <SpinnerElement />
        </FlexBoxElement>
      )}
    </ContainerElement>
  );
};

export { GroupNotesScreen };
