import { keyframes, stylesheet } from 'typestyle';
import { colors } from '../../domain/common/constants/color.constants';

const SpinnerElement = (): JSX.Element => (
  <div className={classNames.element} />
);

const spin = keyframes({
  '0%': {
    transform: 'rotate(0deg)',
  },
  '100%': {
    transform: 'rotate(360deg)',
  },
});

const classNames = stylesheet({
  element: {
    width: 20,
    height: 20,
    border: `5px dashed ${colors.primary}`,
    borderRadius: '50%',
    animationName: spin,
    animationDuration: '0.5s',
    animationIterationCount: 'infinite',
    animationTimingFunction: 'linear',
  },
});

export { SpinnerElement };
