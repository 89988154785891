import { CrudService, IHttpOptions } from 'nest-utilities-client';
import { INuHeaders } from 'nest-utilities-client/distribution/interfaces/nu-headers.interface';
import {
  DependencyList,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

interface ICrudServiceHook<ModelType, HydrateType = null> {
  useHttpOptions: (
    factory: () => IHttpOptions<ModelType> | undefined,
    dependencies: DependencyList,
  ) => IHttpOptions<ModelType> | undefined;
  useGetAll: (httpOptions?: IHttpOptions<ModelType>) => {
    documents: ModelType[];
    hydrated: HydrateType[];
    headers: INuHeaders | null;
    error: string | null;
    isBusy: boolean;
    refresh: () => Promise<void>;
  };
  useGet(
    id?: string | null,
    httpOptions?: IHttpOptions<ModelType>,
  ): {
    document: ModelType | null;
    hydrated: HydrateType | null;
    headers: INuHeaders | null;
    error: string | null;
    isBusy: boolean;
    refresh: () => Promise<void>;
  };
}

// A hook which will provide crud service methods.
function useCrudService<ModelType, HydrateType = null>(
  crudService: CrudService<ModelType, HydrateType>,
): ICrudServiceHook<ModelType, HydrateType> {
  // Hook which will memorize the http options for the service.
  function useHttpOptions(
    factory: () => IHttpOptions<ModelType> | undefined,
    dependencies: DependencyList,
  ): IHttpOptions<ModelType> | undefined {
    // eslint-disable-next-line react-hooks/exhaustive-deps -- We cannot know if the dependency list is exhaustive
    return useMemo<IHttpOptions<ModelType> | undefined>(factory, dependencies);
  }

  // Hook which will fetch all documents.
  function useGetAll(httpOptions?: IHttpOptions<ModelType>) {
    const [documents, setDocuments] = useState<ModelType[]>([]);
    const [hydrated, setHydrated] = useState<HydrateType[]>([]);
    const [headers, setHeaders] = useState<INuHeaders | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [isBusy, setIsBusy] = useState<boolean>(false);

    const fetch = useCallback(
      async function callback() {
        setIsBusy(true);
        try {
          const documents = await crudService.getAll(httpOptions);
          setDocuments(documents.data);
          setHydrated(documents.hydrated);
          setHeaders(documents.headers);
          setError(null);
        } catch (error) {
          setDocuments([]);
          setHydrated([]);
          setError(error as string);
        } finally {
          setIsBusy(false);
        }
      },
      [httpOptions],
    );

    useEffect(
      function effect() {
        fetch();
      },
      [httpOptions, fetch],
    );

    return { documents, hydrated, headers, error, isBusy, refresh: fetch };
  }

  // Hook which will fetch one document.
  function useGet(id?: string | null, httpOptions?: IHttpOptions<ModelType>) {
    const [document, setDocument] = useState<ModelType | null>(null);
    const [hydrated, setHydrated] = useState<HydrateType | null>(null);
    const [headers, setHeaders] = useState<INuHeaders | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [isBusy, setIsBusy] = useState<boolean>(false);

    const fetch = useCallback(
      async function callback() {
        if (typeof id === 'undefined' || id === null) {
          return;
        }
        setIsBusy(true);
        try {
          const document = await crudService.get(id, httpOptions);
          setDocument(document.data);
          setHydrated(document.hydrated);
          setHeaders(document.headers);
          setError(null);
        } catch (error) {
          setDocument(null);
          setHydrated(null);
          setError(error as string);
        } finally {
          setIsBusy(false);
        }
      },
      [id, httpOptions],
    );

    useEffect(
      function effect() {
        fetch();
      },
      [id, httpOptions, fetch],
    );

    return { document, hydrated, headers, error, isBusy, refresh: fetch };
  }

  return { useHttpOptions, useGetAll, useGet };
}

export { useCrudService };
