import { ReactNode } from 'react';
import { NavLink } from 'react-router-dom';
import { stylesheet } from 'typestyle';

interface IProps {
  children: ReactNode;
  href: string;
}

const AnchorElement = (props: IProps): JSX.Element => (
  <NavLink to={props.href} className={classNames.anchor}>
    <p>{props.children}</p>
  </NavLink>
);

const classNames = stylesheet({
  anchor: {
    cursor: 'pointer',
    display: 'block',
    textDecoration: 'none',
  },
});

export { AnchorElement };
