import { load } from 'ts-dotenv';

const environment = load({
  // eslint-disable-next-line @typescript-eslint/naming-convention -- naming convention is not respected
  REACT_APP_API_URL: String,
  // eslint-disable-next-line @typescript-eslint/naming-convention -- naming convention is not respected
  REACT_APP_ENVIRONMENT: String,
});

export { environment };
