import { useCallback } from 'react';
import { authenticationService } from '../../domain/authentication/authentication.service';
import { dictionary } from '../../domain/common/constants/dictionary.constants';
import { useForm } from '../../domain/form/form.hook';
import { useNotificationContext } from '../../domain/notification/notification-context.hook';
import { ActionButtonElement } from '../elements/action-button.element';
import { InputTextElement } from '../elements/input-text.element';
import { SpacerElement } from '../elements/spacer.element';

function ForgotPasswordFormTemplate(): JSX.Element {
  const { onSubmitForm: onSubmit } = useForm();
  const { appendError, appendSuccess } = useNotificationContext();

  const handleResetPassword = useCallback(
    async (formData: FormData) => {
      try {
        await authenticationService.forgotPassword(formData);
        appendSuccess(dictionary.texts.resetPasswordRequest);
      } catch (error) {
        appendError(dictionary.texts.resetPasswordFailed);
      }
    },
    [appendSuccess, appendError],
  );

  return (
    <form onSubmit={onSubmit(handleResetPassword)}>
      <InputTextElement
        name="email"
        type="email"
        placeholder={dictionary.literals.email}
        required
      />
      <SpacerElement />
      <ActionButtonElement
        role="submitClosestForm"
        children={dictionary.literals.resetPassword}
      />
    </form>
  );
}

export { ForgotPasswordFormTemplate };
