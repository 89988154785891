import {
  ChangeEvent,
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { classes, keyframes, stylesheet } from 'typestyle';
import { dictionary } from '../../domain/common/constants/dictionary.constants';
import dropdownIcon from '../../assets/graphics/dropdown-icon.svg';

interface IOption {
  value: string;
  label: string;
}

interface IProps {
  isRequired?: boolean;
  name?: string;
  placeholder: string;
  emptyOptionLabel?: string;
  isSearchable?: boolean;
  defaultValue?: string;
  options: IOption[];
  onChange?: (value: string) => void;
}

function InputSelectElement(props: IProps): JSX.Element {
  const [value, setValue] = useState<string | undefined | null>(
    props.defaultValue ?? undefined,
  );
  const [isOpen, setIsOpen] = useState(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const searchInputElement = useRef<HTMLParagraphElement>(null);
  const containerElement = useRef<HTMLDivElement>(null);

  const filteredOptions = useMemo(
    () =>
      props.options.filter((option) =>
        option.label.toLowerCase().includes(searchValue?.toLowerCase()),
      ),
    [props.options, searchValue],
  );

  const selectedOption = useMemo(() => {
    const option =
      props.options.find((option) => option.value === value) ?? null;
    if (option === null && value) {
      setValue(undefined);
    }

    return option;
  }, [props.options, value]);

  const closeOptions = useCallback(() => {
    setSearchValue('');
    setIsOpen(false);

    if (containerElement.current) {
      containerElement.current.blur();
    }
  }, []);

  const handleBodyClick = useCallback(
    (event: MouseEvent) => {
      if (containerElement.current?.contains(event.target as Node) === false) {
        closeOptions();
      }
    },
    [closeOptions],
  );

  function handleOpenOptions() {
    setIsOpen(true);
  }

  const handleOnClickOption = useCallback(
    (option: IOption | null) => {
      setValue(option?.value);
      if (option?.label) {
        props.onChange?.(option.value);
      }
      closeOptions();
    },
    [closeOptions, props],
  );

  const handleOnInput = useCallback(
    (event: ChangeEvent<HTMLParagraphElement>) => {
      setSearchValue(event.currentTarget.textContent ?? '');
    },
    [],
  );

  const handleOnClickClear = useCallback(() => {
    handleOnClickOption(null);
    if (searchInputElement.current !== null && props.isSearchable === true) {
      searchInputElement.current.textContent = props.placeholder;
    }
  }, [handleOnClickOption, props.isSearchable, props.placeholder]);

  useEffect(() => {
    if (
      isOpen === true &&
      searchInputElement.current !== null &&
      props.isSearchable === true
    ) {
      searchInputElement.current.focus();
    }
  }, [isOpen, props.isSearchable]);

  useEffect(() => {
    document.addEventListener('click', handleBodyClick);
    return () => {
      document.removeEventListener('click', handleBodyClick);
    };
  }, [handleBodyClick]);

  return (
    <Fragment>
      {props.name !== undefined && (
        <input
          type="hidden"
          name={props.name}
          value={selectedOption?.value ?? '{{undefined}}'}
        />
      )}
      <div
        ref={containerElement}
        className={classNames.container}
        onFocus={handleOpenOptions}
        tabIndex={0}
      >
        <div className={classNames.select} onClick={handleOpenOptions}>
          {(isOpen === false || !props.isSearchable) && (
            <p className={classes(classNames.selectContent, classNames.text)}>
              {selectedOption?.label}
              {value === null && (props.emptyOptionLabel ?? props.placeholder)}
              {value === undefined && props.placeholder}
            </p>
          )}
          {isOpen && props.isSearchable && (
            <p
              ref={searchInputElement}
              className={classes(classNames.selectContent, classNames.text)}
              contentEditable={props.isSearchable}
              onInput={handleOnInput}
            />
          )}
          <img
            src={dropdownIcon}
            className={classes(
              classNames.dropdownIcon,
              !isOpen ? classNames.rotateDown : classNames.rotateUp,
            )}
            alt="Dropdown Icon"
          />
        </div>
        {isOpen === true && (
          <div
            className={classNames.options}
            style={{ top: props.isSearchable ? '100%' : undefined }}
          >
            {props.isRequired !== true && (
              <div className={classNames.option} onClick={handleOnClickClear}>
                <p className={classNames.text}>
                  {props.emptyOptionLabel ?? dictionary.literals.clearSelection}
                </p>
              </div>
            )}
            {filteredOptions.map((option) => (
              <div
                className={classNames.option}
                key={option.value}
                onClick={() => handleOnClickOption(option)}
              >
                <p className={classNames.text}>{option.label}</p>
              </div>
            ))}
          </div>
        )}
      </div>
    </Fragment>
  );
}

const scaleInAnimationName = keyframes({
  '0%': {
    transform: 'scaleY(0.9)',
    opacity: 0,
  },
  '100%': {
    transform: 'scaleY(1)',
    opacity: 1,
  },
});

const classNames = stylesheet({
  container: {
    position: 'relative',
  },
  select: {
    width: '100%',
    backgroundColor: '#ccc',
    borderRadius: 10,
    boxShadow: '0 10px 20px 0 #00000022',
    cursor: 'pointer',
  },
  options: {
    position: 'absolute',
    top: 10,
    left: 10,
    right: 10,
    maxHeight: 200,
    overflowY: 'scroll',
    backgroundColor: 'white',
    borderRadius: 10,
    boxShadow: '0 10px 20px 0 #00000022',
    zIndex: 999,
    transformOrigin: 'top',
    animationName: scaleInAnimationName,
    animationDuration: '0.3s',
  },
  option: {
    padding: '10px 20px',
    cursor: 'pointer',
    $nest: {
      '&:hover': {
        backgroundColor: '#eee',
      },
    },
  },
  dropdownIcon: {
    position: 'absolute',
    right: 20,
    top: 16,
    width: 20,
    height: 20,
    transition: 'transform 0.3s',
  },
  rotateDown: {
    transform: 'rotate(180deg)',
  },
  rotateUp: {
    transform: 'rotate(0)',
  },
  text: {
    color: 'black',
    fontSize: '1.1rem',
    fontWeight: 400,
  },
  selectContent: {
    padding: '15px 20px',
    borderRadius: 10,
  },
});

export { InputSelectElement };
