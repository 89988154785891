/**
 * Converts a date into a string in the format dd/mm/yyyy. This is used to
 * display dates in the UI.
 * @param date The date to convert to the European format ('en-GB')
 * @returns European format string representing the given date
 */

// Variable that holds the date formatter. This is created once and then reused
// for all dates that need to be formatted.
const dateTimeFormat = new Intl.DateTimeFormat('en-GB', {
  day: '2-digit',
  month: 'long',
  year: 'numeric',
});

// The Intl.DateTimeFormat() function is used to format the date. The 'en-GB'
// locale is used to ensure that the date is formatted correctly (dd/mm/yyyy) for
// the european style that we want in the application.
const formatDate = (date: Date): string => dateTimeFormat.format(date);

export { formatDate };
