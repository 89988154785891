import { stylesheet } from 'typestyle';
import { colors } from '../../domain/common/constants/color.constants';

const InactivityIndicatorElement = (): JSX.Element => (
  <div className={classNames.inactivityIndicator}></div>
);

const classNames = stylesheet({
  inactivityIndicator: {
    width: '15px',
    height: '15px',
    background: colors.dangerous,
    borderRadius: '50%',
  },
});

export { InactivityIndicatorElement };
