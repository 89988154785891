import { Fragment } from 'react';
import { stylesheet } from 'typestyle';

interface IProps {
  crumbs: string[];
}

function BreadCrumbsElement(props: IProps): JSX.Element {
  return (
    <div className={classNames.container}>
      <h6>
        {props.crumbs.map((crumb, index) => (
          <Fragment key={index}>
            {crumb}
            {index < props.crumbs.length - 1 && <Fragment> &raquo; </Fragment>}
          </Fragment>
        ))}
      </h6>
    </div>
  );
}

const classNames = stylesheet({
  container: {},
});

export { BreadCrumbsElement };
