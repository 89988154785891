import { Fragment, useCallback, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useAuthenticationContext } from '../domain/authentication/authentication-context.hook';
import { dictionary } from '../domain/common/constants/dictionary.constants';
import { useLifeCycle } from '../domain/common/life-cycle.hook';
import { FloatingActionButtonRenderer } from '../domain/floating-action-button/floating-action-button-renderer.component';
import { NotificationRenderer } from '../domain/notification/notification.renderer';
import { EnvironmentFlagElement } from './elements/environment-flag.element';
import { FlexBoxElement } from './elements/flex-box.element';
import { FooterElement } from './elements/footer.element';
import { NavigationBarElement } from './elements/navigation-bar.element';
import { SpacerElement } from './elements/spacer.element';
import { AttendanceScreen } from './screens/attendance.screen';
import { EditUserScreen } from './screens/edit-user.screen';
import { ForgotPasswordScreen } from './screens/forgot-password.screen';
import { GroupNotesScreen } from './screens/group-notes.screen';
import { GroupScreen } from './screens/group.screen';
import { IndexScreen } from './screens/index.screen';
import { LoginScreen } from './screens/login.screen';
import { MarkAttendanceScreen } from './screens/mark-attendance.screen';
import { ResetPasswordScreen } from './screens/reset-password.screen';

// First entry point where the application is rendered.
function Application(): JSX.Element {
  const { validate, isAuthenticated } = useAuthenticationContext();
  const [isValidated, setIsValidated] = useState(false);

  const onMount = useCallback(
    async function effect() {
      try {
        await validate();
      } finally {
        setIsValidated(true);
      }
    },
    [validate],
  );

  useLifeCycle(onMount);

  // Awaiting for the validation of the session token.
  if (isValidated === false) {
    return <p>{dictionary.literals.loading}...</p>;
  }

  return (
    <Fragment>
      <EnvironmentFlagElement />
      <NavigationBarElement />
      {isAuthenticated === false && (
        <Routes>
          <Route path={'*'} element={<Navigate to={'/login'} replace />} />
          <Route path={'/login'} element={<LoginScreen />} />
          <Route path={'/forgot-password'} element={<ForgotPasswordScreen />} />
          <Route
            path={'/forgot-password/:token'}
            element={<ResetPasswordScreen />}
          />
        </Routes>
      )}
      {isAuthenticated === true && (
        <Routes>
          {/* TODO -- Introduce a router config with properties such as a page title */}
          <Route path={'*'} element={<Navigate to={'/'} replace />} />
          <Route path={'/'} element={<IndexScreen />} />
          <Route path={'/groups/:groupId'} element={<GroupScreen />} />
          <Route
            path={'/groups/:groupId/cancelations'}
            element={<GroupNotesScreen />}
          />
          <Route path={'/users/:userId/edit'} element={<EditUserScreen />} />
          <Route path={'/mark-attendance'} element={<MarkAttendanceScreen />} />
          <Route path={'/attendance'} element={<AttendanceScreen />} />
        </Routes>
      )}
      <FlexBoxElement>
        <p>Made with ♥ by Hulan</p>
      </FlexBoxElement>
      <SpacerElement />
      <FooterElement>
        <NotificationRenderer />
        <FloatingActionButtonRenderer />
      </FooterElement>
    </Fragment>
  );
}

export { Application };
